.tip-trigger {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0px;
    border-radius: 50px;
    height: 80px;
    width: 80px;
    box-shadow: 1px 1px 30px rgb(99 102 241 / 20%);
    overflow: hidden;
    transition: width 0.4s ease-in-out;
    justify-content: flex-start;

    div:first-child {
        width: 80px;
    }

    .notice {
        font-size: 13px;
        color: var(--bluegray-700);
        width: 0;
        transition: all 0.6s linear;
        visibility: hidden;
        opacity: 0;

        span {
            font-weight: 600;
            display: block;
            margin-bottom: 4px;
            margin-top: -3px;
        }

        p {
            margin: 0;
            width: 210px;
            box-sizing: border-box;
            padding-right: 12px;
        }
    }

    &:hover {
        width: 290px;
        cursor: pointer;
        box-shadow: 1px 1px 30px rgb(99 102 241 / 5%);

        .notice {
            width: unset;
            visibility: visible;
            opacity: 1;
        }
    }
}
