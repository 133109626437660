.calendar-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &__header {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        box-sizing: border-box;

        .p-buttonset {
            border-radius: 0;
            box-shadow: none;
        }
    }
}
