.body-item {
  display: flex;
  height: 5.75rem;
  justify-content: center;
  align-items: center;

  &__default {
    display: flex;
    width: 100%;
    height: 5.75rem;
    justify-content: center;
    align-items: center;
  }

  &__photo {
    display: flex;
    width: 8.5rem;
    height: 5.75rem;
    justify-content: center;
    align-items: center;
    grid-gap: 0.625rem;
    border-radius: 0.375rem;
    background: var(--Lara-Blue-blue-50, #F5F9FF);
    margin: 12px 0;
    object-fit: cover;

    .pi {
      color: #BCC3CD;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0.125rem;
    flex: 1 0 0;

    span {
      color: #9FA9B7;
      font-family: "Object Sans", sans-serif;
      font-size: .875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3125rem;
    }
  }
}