.refinement-form {
    display: flex;
    flex-direction: column;

    textarea.p-inputtext {
        font-size: 13px;

        &:focus {
            box-shadow: none;
        }
    }

    .p-button {
        margin-top: 10px;
        background: var(--indigo-50);
        border: 1px solid var(--indigo-100);
        color: var(--indigo-400);
        font-weight: normal;
    }
}


.team-events {
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 12px;

    .team-events-item {
        border: 1px solid var(--blue-100);
        border-radius: 12px;
        min-height: 126px;
        background: var(--blue-50);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 18px;

        .events-item-body {
            text-align: center;

            .player {
                font-size: 14px;
                font-weight: 600;
                color: var(--bluegray-800);
            }

            .assistant {
                font-size: 12px;
                color: var(--bluegray-500);
            }
        }

        .p-tag {

            &.event-type {
                position: absolute;
                top: -12px;
            }

            &.event-minute {
                position: absolute;
                bottom: -12px;
                background: var(--blue-50);
                border: 1px solid var(--blue-100);
                color: var(--blue-500);
            }

            &.p-tag-success {
                background: var(--green-50);
                border: 1px solid var(--green-100);
                color: var(--green-500);
            }

            &.p-tag-info {
                background: var(--bluegray-50);
                border: 1px solid var(--bluegray-100);
                color: var(--bluegray-500);
            }

            &.p-tag-warning {
                background: var(--orange-50);
                border: 1px solid var(--orange-100);
                color: var(--orange-400);
            }

            &.p-tag-danger {
                background: var(--red-50);
                border: 1px solid var(--red-100);
                color: var(--red-400);
            }

            &.refinement {
                margin-top: 10px;
                font-size: 12px;
                margin-bottom: 4px;
                font-weight: normal;
                background: var(--indigo-50);
                border: 1px solid var(--indigo-100);
                color: var(--indigo-400);

                &:hover {
                    cursor: pointer;
                }

                &.active {
                    background: var(--indigo-400);
                    color: var(--indigo-50);
                }
            }
        }
    }
}
