.live-panel {

    .control__request {
        width: 100%;
    }
}

.layout-mobile {
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 60px;

    &.notop {
        padding-top: 0px;
    }

    .aside {
        width: 260px;
        height: 100vh;
        background-color: var(--indigo-900);
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        transform: translateX(-260px);
        transition: all .3s ease-in-out;

        &.shown {
            transform: translateX(0);
        }
    }

    .page-toolbar {
        margin-top: 0;
        height: auto;

        .container {
            display: flex;
            flex-direction: column;

            .search {

                .p-input-icon-left {
                    width: 100%;

                    input {
                        width: 100% !important;
                        text-align: center;
                    }
                }
            }

            &>div {
                height: 40px;
                display: flex;
                width: 100%;
                justify-content: center !important;
                padding: 0 .5rem;

                &.btn-group {

                    .p-button {
                        padding: 0.3rem 0.5rem;
                        font-size: .8rem;
                    }
                }
            }
        }
    }

    .page {
        width: 100%;

        .topbar {
            height: 60px;
            background: #fff;
            box-shadow: 0px 1px 0px #DEE2E6;
            margin-bottom: 14px;

            .container {
                height: 60px;
            }
        }

        .events-requests {
            padding-top: 1rem;

            .toolbar {
                display: none;
            }

            .team-request-container {
                padding: 0;

                .request-item {
                    width: 100%;
                }
            }

            .content {
                flex-direction: column;

                .matches-feed {
                    width: 100%;
                }
            }
        }

        &>.container {
            height: calc(100vh - 74px);
            border-bottom: none;
            position: relative;
            margin: 0 auto;
            max-width: 700px;

            .container-grid {
                display: grid;
                grid-template-columns: 6fr 2.8fr;
                grid-gap: 50px;
            }
        }
    }

    &.collapsed {

        .sideblock {
            filter: none;
        }
    }
}
