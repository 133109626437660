.support {
  display: flex;
  grid-gap: 1.5rem;
  padding: 2rem 0;

  &__form {
    width: 70%;
  }

  &__side-notes {
    width: 30%;
  }

  &__title {
    width: 100%;
    padding: 1.25rem;
    border-radius: 1rem;
    background: #e5f1fd;

    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;

    span {
      color: var(--blue-700);
      cursor: pointer;
    }
  }
}