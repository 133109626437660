.roles-detail {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  height: calc(100vh - 91.67px);

  &__buttons {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: .5rem;

    position: fixed;
    bottom: 1.87rem;
    right: 4.37rem;
    margin-top: 24px;
    margin-left: auto;

    // position: absolute;
    // bottom: 1.25rem;
    // right: 0;

    z-index: 99;
  }
}  