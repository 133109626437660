.clubs-profile {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__card {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 24px 18px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &:not(:first-child) {
            gap: 24px;
        }
     }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            color: var(--Lara-Global-textColor, #495057);
            font-family: Inter;
            font-size: 17.5px;
            font-style: normal;
            font-weight: 800;
            line-height: 26.25px; /* 150% */
        }
    }

    &__switch {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__inputs {
        display: flex;
        gap: 24px;
    }

    &__color {
        display: flex;
        gap: 4px;
        .p-colorpicker {
            input {
                width: 42px;
                height: 42px;
            }
        }

        .p-inputtext {
            width: 100%;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 24px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        &.border {
            box-sizing: border-box;
            padding-bottom: 20px;
            border-bottom: 1px solid #E9ECEF;
        }
    }

    &__label {
        color: var(--Lara-Global-textColor, #495057);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 100% */

        &.muted {
            color: var(--Lara-Blue-Gray-bluegray-400, #818ea1);
        }
    }

    &__fileupload {
    }

    textarea {
        resize: none;
        height: 77.5px;
    }
}
