.mediaForm-detail__main {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  
  &-col {
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    grid-gap: 20px;
    flex: 1;
    
    &.flex {
      gap: 0;
    }
  }

  &-divider {
    color: var(--Lara-Blue-Gray-bluegray-300, #9FA9B7);
    text-align: center;
    font-family: "Object Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    width: 100%;
    margin: 8px 0;
    display: flex;
    align-items: center;
    gap: 12px;
    
    span {
      background: var(--Lara-Root-surface-border, #DFE7EF);
      height: 1px;
      width: 100%;
      display: inline-block;
    }
  }
  
  & .p-calendar {
    padding: 0;
    border: none !important;
    
    input {
      border-radius: 6px;
      border: 1px solid var(--Lara-Input-Field-inputBorder, #CED4DA);
      color: var(--Lara-Action-Icons-actionIconHoverColor, #343A40);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    
    & .p-button {
      background: transparent;
      border: none;
      right: 0;
      height: 100%;
      
      @media (hover: hover){
        &:hover{
          color: unset;
        }
      }
    }
  }
  
  & .custom-upload {
    width: 100%;
    
    & .p-button {
      width: 100%;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid var(--Lara-Button-buttonBorder, #6466E9);
      background: transparent;
      color: var(--Lara-Button-buttonBg, #6466E9);
      font-size: 14px;
      font-weight: 400;
      line-height: 17px; 
      transition: .3s ease;
      margin: 0;
      
      @media (hover: hover){
        &:hover{
          cursor: pointer;
          opacity: .8;
        }
      }
    }
  }
  
  & .p-inputtext {
    min-height: 42px;
  }
  
  & .tags-controls_item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    
    h6 {
      margin: 0 !important;
      color: var(--Lara-Global-textColor, #495057);
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    
    .p-autocomplete {
      width: 100%;
      min-height: 42px;
      
      .p-autocomplete-multiple-container {
        padding: 10.5px;

        .p-autocomplete-token {
          padding: .35rem .5rem;
          font-size: .8rem;
          margin-bottom: .35rem;

          .p-autocomplete-token-icon {
            margin-left: .35rem;
            font-size: .9rem;
          }
        }

        .p-autocomplete-input-token {
          // max-width: 220px;
          margin-bottom: .35rem;

          input {
            color: var(--Lara-Action-Icons-actionIconColor, #6C757D);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
          }
        }
      }
    }

    .p-inputtext {
      width: 100%;
    }
  }
  & .fileupload {
    width: 100%;
    height: 100%;
    height: unset;
    min-height: unset;
    flex: 1 0 auto;
    
    & > div:first-child {
      width: 100%;
    }
    
    & .p-fileupload {
      min-height: unset !important;
    }
    
  }
}