// .candidatesFlow {
//     width: 1048px;
//     border-radius: 12px;
//     padding: 32px 0px 0px;
//     box-sizing: border-box;
//     position: relative;
//     min-height: 86vh;
//
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items:  flex-start;
//     // top: 100px;
//     // left: 100px;
//     background-color: #ffffff;
// }
.duplicated__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:  center;
    width: 1048px;
    border-radius: 12px;
    box-sizing: border-box;
    position: relative;
    min-height: 600px;

    background-color: #ffffff;
    padding: 0 20px;
    //max-width: 350px;
    transition: all .6s ease-in-out;
    // overflow-x: auto;
    // overflow-y: auto;
    // display: flex;
    height: 610px;

    .notice {
        width: 100%;
        background: var(--indigo-50);
        border: 1px solid var(--indigo-100);
        border-radius: 12px;
        color: var(--indigo-700);
        padding: 5px 20px;
        text-align: center;
        font-size: 13px;
    }

    .duplicated__items {
        /*display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;*/
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 12px;
        width: 100%;
        padding: 14px 0 40px 0;
        //align-content: space-between;
    }

    .duplicated__action {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 20px;
        grid-gap: 10px;

        .p-button {
            white-space: nowrap;

            &.p-button-success {
                background: var(--green-50);
                border-color: var(--green-100);
            }

            &.p-button-secondary {
                background: var(--bluegray-50);
                border-color: var(--bluegray-100);
            }

            &.p-button-danger {
                background: var(--red-50);
                border-color: var(--red-100);
            }
        }
    }
    .player__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        background: #F7F7F7;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        //margin-right: 1rem;
        place-self: start;
        &.isDuplicated {
            background: linear-gradient(90deg, #F4F5FF 0%, #FBFBFF 90.33%);
        }
        .playerTeams {
            display: flex;
            padding-top: 10px;
            flex-wrap: wrap;
            justify-content: center;

            .p-tag {
                max-height: 20px;
                font-size: 11px;
                font-weight: normal;
                padding: 2px 7px;
                background: var(--blue-50);
                border: 1px solid var(--blue-100);
                color: var(--blue-500);
            }
        }
    }

    .rectangle-2 {
        display: flex;
        flex-direction: column;
        width: 100%;

        //background: rgba(255, 255, 255, 0.8);
        border-radius: 15px;

        align-items: center;
        cursor: pointer;
        //position: relative;
        .photo {
            position: relative;
            object-fit: contain;
            width: 100px;
            top: -25px;
        }

        .playerData {
            width: calc(100% - 20px);
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;

            background: #F5F9FF;
            box-shadow: 0 4px 10px rgba(191, 219, 254, 0.3);
            border-radius: 15px;
            &.fio {
                margin-top: 0px;
            }
            &>div:not(.icon) {
                width: 100%;
                font-size: 14px;
                padding-left: 12px !important;
            }

            &.bday {
                justify-content: flex-start;

                .icon {

                    img {
                        width: 22px;
                        height: 22px;
                    }
                }
            }

        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;

            background: #D0E1FD;
            border-radius: 15px;

            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;

            text-align: center;

            color: #8183F4;
        }

    }
    .player__checker {
        margin-top: 10px;
        margin-bottom: 6px;

        label {
            padding-left: 6px;
            font-size: 14px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &.moreTwo {
        .duplicated__items {
            grid-template-columns: 1fr 1fr 1fr;
            width: 80%;
        }
        .duplicated__action {
            width: 15%;
            margin-left: 10px;
        }
        .player__container {
            width: 100%;
        }

    }
    &.moreThree {
        justify-content: space-between;
        align-items: center;
        .duplicated__items {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            width: 80%;
        }
        .duplicated__action {
            width: 15%;
            //align-self: center;
            margin-left: 10px;
        }
        .player__container {
            width: 100%;
        }

    }
}
.p-dialog-header {
    //border-radius: 18px;
    text-align: center;
}
.p-dialog .p-dialog-content:last-of-type {
    border-radius: 0;
}
