.auth2 {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100vh;
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    overflow-anchor: none;
    overflow-x: hidden;
    flex: 1 1 auto;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 48px;
    }

    &__bg {
        @media (min-width: 768px) {
            flex: 1 1 auto;
            position: relative;
            background: center / cover no-repeat url("./img/authBg.png");
        }

        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 16px;
        }

        &-authText {
            max-width: 660px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50%;

            @media (max-width: 768px) {
                display: none;
            }
        }

        &-authTextMob {
            object-fit: contain;
            width: 65%;
            min-width: 240px;

            @media (min-width: 769px) {
                display: none;
            }
        }
    }

    &__container {
        flex: 0 1 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5rem 3.75rem;
        animation: opacityIn 0.5s;

        @media (max-width: 992px) {
            padding: 30px 16px;
            flex: 0 1 400px;
        }

        @media (max-width: 768px) {
            width: 100%;
            justify-content: flex-start;
            padding-top: 0;
        }
    }

    &__title {
        color: #495057;
        font-family: Inter, sans-serif;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.75rem;

        @media (max-width: 768px) {
            font-size: 24.5px;
            line-height: 24.5px;
            text-align: center;
        }
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        grid-gap: 1.25rem;
        align-self: stretch;
    }

    &__input {
        color: #6c757d !important;
        font-family: Inter, sans-serif !important;
        font-size: 0.875rem !important;
        font-style: normal;
        line-height: 1.3125rem;

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            grid-gap: 10px;
            width: 100%;

            &-label {
                display: flex;
                align-items: center;
                gap: 7px;
                color: #495057;
            }

            &_full {
                grid-column: 1 / -1;
            }
        }

        &-label {
            color: var(--lara-global-textcolor);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px !important;

            span.grey {
                color: var(--Lara-Blue-Gray-bluegray-300, #9fa9b7);
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
            }
        }

        &_invalid {
            border-color: #f12f5e !important;
        }
    }

    input {
        padding: 10.5px;
    }

    &__password {
        & > input {
            width: 100%;
            font-size: 14px;
        }
        .p-icon,
        .p-password-show-icon {
            top: 50%;
            transform: translateY(-50%);
        }

        input {
            padding: 10.5px;
            display: flex;
            min-height: 42px;
            align-items: center;
            width: 100%;

            border-radius: 0.375rem;
            border: 1px solid #ced4da;
            background: #fff;

            color: #6c757d;
            font-family: Inter, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3125rem;
        }

        &_invalid {
            input {
                border-color: #f12f5e;
            }
        }
    }

    &__calendar {
        & > input {
            width: 100% !important;
            font-size: 14px;
        }
        .p-button {
            top: 50%;
            transform: translateY(-50%);
            background: #ffffff00;
            height: 44px;
            border: none;
            right: 0px;
            border-top-right-radius: 0.375rem;
            border-bottom-right-radius: 0.375rem;
            border: 1px solid #ced4da00;
            &:hover {
                border: 1px solid #6366F1;
            }
        }

        input {
            padding: 10.5px;
            display: flex;
            min-height: 42px;
            align-items: center;
            width: 100%;

            border-radius: 0.375rem !important;
            border: 1px solid #ced4da;
            background: #fff;

            color: #6c757d;
            font-family: Inter, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3125rem;
        }

        &_invalid {

            .p-inputtext, .p-button {
                border-color: #f12f5e;

            }
        }
    }

    &__button {
        width: 100%;
        border-color: #6466e9 !important;
        background: #6466e9 !important;

        .p-button-label {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.0625rem;
        }

        &_outlined {
            color: var(--Lara-Button-buttonBg, #6466e9);
            background: none !important;

            .p-button-label {
                font-family: Inter, sans-serif;
                font-size: 14px;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.0625rem;
                color: #6466e9;
            }
        }
    }

    &__checkbox {
        &-label {
            color: #495057;
            font-family: "Inter V", sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            a {
                cursor: pointer;
                text-decoration: none;
                color: #6466e9;
            }
        }

        &-container {
            display: flex;
            align-items: center;
            grid-gap: 0.5rem;
            align-self: stretch;
        }
    }

    .p-divider.p-divider-horizontal {
        margin: 0;
    }
}

.p-toast {
    width: max-content !important;
    max-width: 80% !important;
    z-index: 1000;
    .p-toast-message {
        margin: 0 0 1rem 0;

        @media (max-width: 450px) {
            width: 100%;
            border-radius: 0;
        }

        & .p-toast-message-content {
            align-items: center;
            padding: 1rem !important;
            border-width: 0 0 0 6px !important;

            .p-toast-message-icon {
                font-size: 1rem !important;
            }
        }
        & .p-toast-message-text {
            margin: 0 0 0 1rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }
    }
}
