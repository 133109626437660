.button {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.875rem;
  width: 100%;

  box-sizing: border-box;
  padding: .65625rem 1.09375rem;
  text-align: center;

  border-radius: .375rem;
  border: 1px solid var(--lara-button-buttonborder);
  background: var(--lara-button-buttonbg);

  color: var(--lara-button-buttontextcolor);
  font-family: var(--font-family);
  font-size: .875rem;
  font-style: normal;
  line-height: 1.0625rem;

  transition: color 0s, background .2s, opacity .3s;

  @media (any-hover: hover){
    
    &:hover,
    &:enabled:hover {
      cursor: pointer;
      opacity: .9;
    } 
  }

  &_outlined-blue {
    background: #FFF;
    border: 1px solid #1B3F86;
    color: #1B3F86;

    @media (any-hover: hover){
      &:hover,
      &:enabled:hover {
        background: #1B3F86;
        color: var(--lara-button-buttontextcolor);
      }
    }
  }

  &_outlined-red {
    background: #FFF;
    border: 1px solid var(--lara-button-buttonborder);
    color: var(--lara-button-buttonborder);

    &:hover,
    &:enabled:hover {
      background: var(--lara-button-buttonborder);
      color: var(--lara-button-buttontextcolor);
    }
  }

  &_blue {
    border: 1px solid #1B3F86;
    background: #1B3F86;

    &:hover,
    &:enabled:hover {
      background: #16336c;
    }
  }

  &_orange {
    border: 1px solid var(--lara-button-dangerbuttonbg);
    background: var(--lara-button-dangerbuttonbg);

    &:hover,
    &:enabled:hover {
      background: #e06814;
    }
  }

  &_text {
    background: transparent;
    border: 1px solid transparent;
    color: var(--lara-button-buttonborder);

    &:hover,
    &:enabled:hover {
      background: transparent;
      border: 1px solid var(--lara-button-buttonborder);
      color: var(--lara-button-buttonborder);
    }
  }

  &_text-blue {
    background: transparent;
    border: 1px solid transparent;
    color: #1B3F86;

    &:hover,
    &:enabled:hover {
      background: transparent;
      border: 1px solid #1B3F86;
      color: #1B3F86;
    }
  }

  &_delete {
    border: 1px solid #E50076 !important;
    background: #E50076 !important;

    &:hover,
    &:enabled:hover {
      opacity: 0.8;
    }
  }
}

.p-button:focus,
.p-button.p-button-secondary:enabled:focus {
  box-shadow: none;
}

.p-button-label {
  flex: none;
  font-weight: 400;
}