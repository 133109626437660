@font-face {
    font-family: "Object Sans";
    font-style: normal;
    font-weight: 400; // Regular
    src: url('./fonts/ObjectSans-Regular.otf') format('truetype');
}

@font-face {
    font-family: "Object Sans";
    font-style: normal;
    font-weight: 800; // Heavy
    src: url('./fonts/ObjectSans-Heavy.otf') format('truetype');
}

body {
    //font-family: Object Sans;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    overflow-anchor: none;
    overflow-x: hidden; //чтоб слайдер скрол боковой не давал
}