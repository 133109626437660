.clubs-application {


    &__notfound {
        color: var(--Lara-Global-textSecondaryColor, #6c757d);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px; /* 242.857% */
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: calc(50vh - 150px);
    }
}
