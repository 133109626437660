.side-profile-editor {
  display: flex;
  width: 100%;
  padding: 1.25rem 1.5rem 0 1.5rem;
  flex-direction: column;
  grid-gap: 1.25rem;

  background: #FFF;

  &__title {
    color: #495057;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1.5rem;
    align-self: stretch;
  }
}