.switch-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  grid-gap: 8px;

  .p-inputswitch.p-highlight .p-inputswitch-slider {
    background: #5457CD;
  }

  label {
    font-family: "Object Sans";
    color: var(--Lara-Global-textColor, #495057);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px; /* 100% */
  }
}