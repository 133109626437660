.form-body {

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    grid-gap: 1rem;
    border-top: 1px solid var(--lara-root-surface-border);
    padding: 24px;

    &_empty {
      border-top: none;
    }
  }

  &__icon {
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: auto;
  }

}

.ql-editor {
  background: transparent !important;

  &.ql-blank::before {
    font-style: normal !important;
  }
}

.mediaForm-detail__body {
  padding: 0 !important;
  
  & .p-card-content {
    padding: 0;
  }
  
  & .p-card-header {
    padding: 24px 24px 0;
    color: var(--Lara-Global-textColor, #495057);
    font-size: 17.5px;
    font-weight: 800;
    line-height: 26.25px;
  }
}