.non-ideal-state {
    display: flex;
    min-height: 400px;
    align-items: center;
    justify-content: center;
    width: 100%;

    .card {
        width: 360px;
        background: #fff;
        box-sizing: border-box;
        min-height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        border-radius: 12px;
        box-shadow: 1px 1px 20px rgb(99 102 241 / 7%);

        .pi {
            font-size: 50px;
            color: var(--blue-200);
            margin-bottom: 60px;
        }

        .notice {
            padding: 0 40px 20px;
            text-align: center;
            color: var(--bluegray-400);
            font-size: 14px;
        }
    }
}
