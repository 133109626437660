.side-profile {
    width: 32% !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;

    &__overlay {
        z-index: 901 !important;
    }

    .p-accordion {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-tab {
            border-bottom: 1px solid #e9ecef !important;

            padding-bottom: 20px !important;
        }

        &-header-link {
            flex-direction: row-reverse;
            justify-content: space-between;
            background: none !important;
            border: none !important;
            padding: 0 !important;
            border-radius: 0;

            &:focus {
                box-shadow: none !important;
            }
        }

        &-content {
            border: 0;
            padding: 0;
            margin: 0;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .p-sidebar {
        &-content {
            padding: 20px 24px 0px 24px !important;
        }

        &-header {
            padding: 12px 24px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__button-remove {
        border-color: #ef4444 !important;
        background: #ef4444 !important;
        padding: 8.5px;
        color: #fff !important;
        font-size: 14px !important;

        // &:hover {
        //     background: #ef4444;
        // }
    }

    .fileupload__label {
        display: flex;
        flex-direction: column;

        span {
            margin: 0;
        }
    }

    .fileupload__field {
        justify-content: unset;

        .p-fileupload {
            height: 177px;
        }
    }

    &__it {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    textarea {
        resize: none;
        height: 92px;
    }

    .p-datepicker {
        max-width: unset !important;
    }
}

.newsletter {
    &__container {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
    }

    &__title {
        color: var(--Lara-Global-textColor, #495057);
        font-feature-settings: "liga" off, "clig" off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }

    input::placeholder,
    .p-multiselect-label,
    .p-inputtextarea,
    .p-inputtext {
        color: var(--Lara-Input-Field-inputTextColor, #495057);

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
    }
}
