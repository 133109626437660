.fileupload {
  display: flex;
  grid-gap: 1rem;
  align-self: stretch;
  flex-wrap: wrap;
  min-height: 100%;
  width: 100%;

  .cropper-modal {
    display: none;

    &_visible {
        display: block;
    }
}

  .cropper {
    width: 100%;
    position: relative;

    &-container {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
  }

  &__field {
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
    flex-direction: column;
    grid-gap: 0.625rem;
    flex: 1 0 0;
    height: 100%;

    &_upload {
      // margin-top: -1rem;
    }

    .p-fileupload {
      width: 100%;

      &.not-valid {
        border-radius: 0.375rem;
        border: 1px solid #F12F5E;
      }

      .p-button.p-fileupload-choose {
        width: 100%;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        grid-gap: 0.4375rem;
        flex: 1 0 0;
        padding: 0;

        border-radius: 0.375rem;
        border: none;
        background: var(--lara-panel-panel-content-bg, #FFF);
        color: #BDBDBD;

        &:hover {
          border: none;
          background: var(--lara-panel-panel-content-bg, #FFF);
          color: #3D75E3;
        }

        .p-button-label{
          flex: none;
          opacity: 0;
          width: 0;
        }

        .p-button-icon-left {
          margin: 0;
        }
      }
    }
  }

  &__label {
    display: flex;
    font-size: 0.875rem;
    font-weight: 400;
    white-space: nowrap;
    color: var(--Lara-Global-textColor, #495057);
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px !important; 

    span {
      color: var(--Lara-Blue-Gray-bluegray-400, #818EA1);
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 14px !important; 
      margin-left: 5px;
    }
  }

  &__input {
    display: flex;
    padding: 0.65625rem;
    align-self: stretch;
    border-radius: 0.375rem;
    border: 1px solid var(--surface-300);
    min-height: calc(100% - 1.625rem);

    &:focus {
      border: 1px solid var(--blue-900);
      box-shadow: none !important;
    }

    &:hover {
      border: AccentColor;
    }

    &_invalid {
      border: 1px solid var(--red-700);
    }

    &_area {
      height: 5.875rem;
    }

    &_item {
      display: flex;
      max-height: 11rem;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      grid-gap: 0.4375rem;
      z-index: 10;
      padding: 0;
      position: relative;

      border: 1px solid #CED4DA;
      background: var(--lara-panel-panel-content-bg, #FFF);

      img, .p-image-preview-container {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        min-height: 11rem;
        object-fit: contain;
        height: 100%;
      }
    }

    &_btns {
      position: absolute;
      background: none;
      border: none;
      z-index: 10;
      opacity: 0;
      height: 100%;
      min-width: 100%;
      transition: .3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &:hover {
        border-radius: 0.375rem;
        border: 1px solid #CED4DA!important;
        background: linear-gradient(0deg, rgba(84, 111, 165, 0.50) 0%, rgba(84, 111, 165, 0.50) 100%)!important;
        opacity: 1;
      }
    }


  }

  &__remove-btn, &__preview-btn {
    background: none;
    border: none;

    .pi {
      font-size: 2rem;
    }
  }
  
  .p-button-label {
    display: none;
  }
}