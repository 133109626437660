.queries-verify {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__table {
        &-item {
            display: flex;
            align-items: center;
            gap: 8px;

            & > img {
                width: 24px;
                height: 24px;
            }
        }
    }

    .header__title {
        padding: 0;
    }

    .p-buttonset {
        overflow: auto;
        box-shadow: unset;
        border-radius: 0;
    }

    .p-selectbutton .p-button {
        border-color: #6466e9;
        color: #6466e9;

        &.p-highlight {
            background: #6466e9;
            color: #fff;
        }
    }

    .p-datatable .p-datatable-thead > tr > th {
        width: 100% !important;
    }
}
