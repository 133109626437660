.side-profile {
    width: 32% !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;

    &__overlay {
        z-index: 901 !important;
    }

    .p-accordion {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-tab {
            border-bottom: 1px solid #e9ecef !important;

            padding-bottom: 20px !important;
        }

        &-header-link {
            flex-direction: row-reverse;
            justify-content: space-between;
            background: none !important;
            border: none !important;
            padding: 0 !important;
            border-radius: 0;

            &:focus {
                box-shadow: none !important;
            }
        }

        &-content {
            border: 0;
            padding: 0;
            margin: 0;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .p-sidebar {
        &-content {
            padding: 20px 24px 0px 24px !important;
        }

        &-header {
            padding: 12px 24px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__button-delete {
        border-color: #ef4444 !important;

        .p-button-label {
            color: #ef4444 !important;
        }
    }


    .fileupload__label {
        display: flex;
        flex-direction: column;

        span {
            margin: 0;
        }
    }

    .fileupload__field {
        justify-content: unset;

        .p-fileupload {
            height: 177px;
        }
    }

    &__it {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    textarea {
        resize: none;
        height: 92px;
    }
}
