.header {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

    &__container {
        padding: 24px 0 0;
    }
    

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 12px;
    }

    &__role {
        color: #6466e9;
        font-size: 10.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.8px; /* 150.476% */
        border-radius: 6px;
        padding: 3.5px 5.6px;
        background: var(--Lara-Indigo-indigo-100, #dadafc);

        &.flex {
            color: var(--Lara-Yellow-yellow-600, #c79807);
            background: var(--Lara-Yellow-yellow-100, #faedc4);
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__title {
        color: var(--Lara-Global-textColor, #495057);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px; /* 170% */
        display: flex;
        gap: 12px;
        align-items: center;
        padding: 0 24px 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        -moz-box-orient: vertical;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        box-orient: vertical;
        border: 0;

        &:focus {
            outline: none;
        }
    }


    .pi-pencil {
        width: 14px;
        height: 14px;
        transition: .3s;
        &:hover {
            color: #9fa9b7;
            cursor: pointer;
        }
    }

    .p-tabmenuitem {
        color: var(--Lara-Blue-Gray-bluegray-300, #9fa9b7);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
}
