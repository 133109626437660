.formInvite {
    padding: 24px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

    &__title {
        color: var(--Lara-Global-textColor, #495057);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px; /* 170% */
        margin-bottom: 12px;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px 24px;
    }

    &__input {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-label {
            color: var(--Lara-Global-textColor, #495057);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        input {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */

            &::placeholder {
                color: var(--Lara-Input-Field-inputIconColor, #6c757d);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px; /* 150% */
            }
        }
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        color: var(--Lara-Input-Field-inputIconColor, #6c757d);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
    }

    &__btn {
        border-radius: 6px;
        border: 1px solid var(--Lara-Button-buttonBorder, #6466e9);
        padding: 10.5px 17.5px;
        text-align: center;
        color: var(--Lara-Button-buttonBg, #6466e9);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 121.429% */
        margin-top: auto;
        height: 44px;
        background-color: transparent;
        transition: 0.3s ease;

        @media (hover: hover) {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
}
