.confirm-modal {
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  padding: 2rem 1.5rem;
  //height: 50vh;
  min-height: 50vh;
  max-height: 80vh;
  width: 40vw;
  background: white;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 1rem;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    margin-left: 30px;
  }

  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: var(--Lara-Root-surface-border, #DFE7EF);
    border-radius: 10px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
  }

  &__header {
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    width: 100%;
    align-items: center;
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--bluegray-900);

    i {
      font-size: 3rem;
      color: var(--red-500);
    }
  }

  &__message {
    width: 100%;
    //height: 100%;
    text-align: center;
    color: var(--bluegray-700);
    font-size: 1.25rem;

    span {
      color: var(--bluegray-900);
      font-weight: 600;
      font-size: 1.25rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    grid-gap: .75rem;
    justify-self: flex-end;
  }
}