.calendar-detail {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;

    &__header {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        padding: 0;
        box-sizing: border-box;

        .p-buttonset {
            border-radius: 0;
            box-shadow: none;
        }
    }

    &__select {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 24px;
    }

    &__tabmenu {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
    }

    &__tags {
      display: flex;
      align-items: center;
      gap: 12px;
    }
}
