.dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-gap: 0.63rem;
    width: 100%;

    &__label {
        color: var(--lara-global-textcolor);
        font-family: var(--font-family);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span.bold {
            color: var(--Lara-Global-textColor, #495057);
            font-size: 14px;
            font-weight: 800;
            line-height: 14px;
        }

        span.grey {
            color: var(--Lara-Blue-Gray-bluegray-300, #9FA9B7);
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
        }
    }

    &__description {
        display: flex;
        padding: 1rem 0.65625rem;
        flex-direction: column;
        grid-gap: 0.125rem;
        align-self: stretch;
        margin-top: -0.63rem;

        border-radius: 0 0 0.375rem 0.375rem;
        background: var(--lara-root-surface-hover);

        color: var(--lara-global-textsecondarycolor);
        font-family: var(--font-family);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.05rem;
    }

    .dropdown {
        display: flex;
        padding: 0.65625rem;
        align-items: flex-start;
        grid-gap: 0.875rem;
        align-self: stretch;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        border-radius: 0.375rem;
        border: 1px solid var(--lara-input-field-inputborder);
        background: var(--lara-input-field-inputbg);

        color: var(--lara-input-field-inputplaceholdertextcolor);
        font-family: var(--font-family);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem;

        &_invalid {
            border: 1px solid var(--lara-input-field-inputerrorbordercolor);
        }
    }
}

.p-dropdown {
    justify-content: space-between;
    &-label {
        // color: var(--Lara-Input-Field-inputPlaceholderTextColor, #6c757d);
        color: var(--Lara-Input-Field-inputTextColor, #495057);
        font-family: "Object Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */

        // ????????????????????
        max-width: 450px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
    }

    &.p-focus {
        box-shadow: none;
    }

    &-panel {
        max-width: calc(100vw - 27.5rem - 50%);
    }

    &-item {
        white-space: normal;
        padding: 0.75rem 1.25rem;

        &:hover {
            opacity: 0.6;
        }

        &.p-highlight {
            color: var(--lara-theme-highlighttextcolor);
            background: #f2e1e1 !important;
        }

        &.p-focus {
            background: initial;
        }
    }
}
