.circle-to-playoff {
  display: flex;
  padding: 0 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  &__pair {
    display: flex;
    padding-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 12px;
    align-self: stretch;
  }

  &__label {
    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }

  &__selects {
    display: flex;
    align-items: center;
    align-self: stretch;

    span {
      width: 40px;

      color: #9FA9B7;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }

  &__dropdown {
    flex: 1 0 0;

    .p-dropdown-label {
      text-align: left;
    }

    .p-dropdown-trigger {
      span {
        font-family: 'primeicons';
      }
    }
  }

  &__button {
    display: flex;
    padding: 8.5px 32px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    align-self: stretch;

    background: none;

    border-radius: 6px;
    border: 1px solid #6466E9;

    color: #6466E9;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .p-button-label {
      flex: none;
    }

    margin-bottom: 24px;
  }

}