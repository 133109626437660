.dreamLineups {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin: 0;
  grid-gap: 30px;

  .request__notification {
      background: var(--indigo-50);
      border: 1px solid var(--indigo-100);
      color: var(--indigo-400);
      margin-left: -20px;
      margin-top: .25rem;
      margin-bottom: .75rem;
      border-radius: .6rem;
      padding: .5rem .75rem .4rem;
      font-size: .75rem;
      line-height: 1.3;
      width: calc(100% + 10px);
  }

  .item__player_block {
      width: calc(100% - 10px);
  }

  .create-lineup-btn {
      width: 100%;
      margin-bottom: 0.75rem;
  }

  .compound__block .compound__block_basic .request__title {
      position: relative;
      top: unset;
      background: var(--indigo-50);
      border: 1px solid var(--indigo-100);
      color: var(--indigo-500);
      font-weight: normal;
      margin-bottom: 50px;
      height: 2.62rem !important;
      padding-left: 12px;
      width: 100%;
      justify-content: flex-start;

      span {

          input {
              color: var(--bluegray-600) !important;
              font-weight: normal !important;
              padding-left: .25rem;

              &:focus {
                  box-shadow: none;
              }
          }
      }
  }

  .compound__block .compound__block_basic .basic__block_arrangement .arrangement__tactic {
      margin-top: -50px;
  }

  .compound__block .compound__block_basic {
      width: 320px;

      &.nobgd {
          background: none;
          border: none;

          .basic__block_arrangement {
              width: 100%;
          }
      }

    .p-tag {
      height: 3rem;
    }
  }

  .fields-group {
    display: flex;
    flex-direction: column;
    background: #fff;
    position: relative;
    border-radius: 12px;
    box-shadow: 1px 1px 20px rgb(99 102 241 / 10%);
    width: 25%;

    .title {
      position: relative;
      top: -12px;
      left: 10px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 21px;
      width: max-content;
      padding: 5px 10px;

      color: var(--blue-50);

      background: var(--blue-500);
      border-color: var(--blue-500);
    }

    .lineupPreview {
      padding: .75rem 1.2rem;
      margin-bottom: 1.2rem;
      margin-left: 15px;
      position: relative;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(59, 130, 246, 0.1);
      border-radius: 15px;
      width: calc(100% - 30px);

      .text {
          font-size: 0.875rem;
          color: var(--bluegray-600);
          margin-bottom: 0.875rem;
      }

      &:first-child{
        margin-top: 10px;
      }

      .tag {
        border: 1px solid var(--blue-100);
        border-radius: 0.375rem;
        line-height: 1rem;
        background: #F5F9FF;
        color: var(--blue-400);
        padding: 0.187rem 0.31rem;
        font-weight: normal;
        font-size: 0.687rem;
      }

      .tag-formation {
        position: absolute;
        //border: none;
        top: -10px;
        right: 10px;
        color: #64748B;
        border: 1px solid var(--bluegray-100);
        font-size: 0.81rem;
      }
    }
  }

  .search {
    display: flex;
    flex-direction: column;
    width: 21.56vw;

    .name-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      grid-gap: 14px;
      width: 100%;

      .p-input-icon-right {
          width: calc(100% - 10px);
      }

      .input {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10.5px;
        grid-gap: 14px;

        width: 100%;
        height: 2.62rem;

        background: #FFFFFF;

        border: 1px solid #CED4DA;
        border-radius: 6px;

        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .text {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.09rem;
        line-height: 1.31rem;

        color: #495057;

        flex: none;
        order: 0;
        flex-grow: 1;
      }
    }
  }

  .button-gr {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 0.625rem;
    margin-top: 2rem;

    .render-btn {
        background: var(--indigo-50);
        color: var(--indigo-400);
        border: 1px solid var(--indigo-100);
    }
  }

  .tactic {
    margin: -40px 0 40px 0;

    .p-inputgroup {
      height: 30px;
      width: 100%;

      .p-inputgroup-addon {
        font-size: 0.75rem;
      }

      .p-dropdown {
        display: flex;
        align-items: center;
        justify-content: start;
        width: max-content;

        .p-dropdown-trigger-icon {
            font-size: .75rem;
        }

        .p-dropdown-label {
          font-size: 0.75rem;
        }
      }
    }
  }

  .mvp {
    margin-top: 1rem;

    .p-inputgroup {
      height: 30px;
      width: 100%;
      .p-inputgroup-addon {
        font-size: 0.75rem;
      }

      .p-dropdown {
        display: flex;
        align-items: center;
        justify-content: start;
        width: max-content;

        .p-dropdown-trigger-icon {
          font-size: .75rem;
        }

        .p-dropdown-label {
          font-size: 0.75rem;
        }
      }
    }
  }

  .manager {
    margin-top: 0.87rem;

    .p-inputgroup {
      height: 30px;
      width: 100%;
      .p-inputgroup-addon {
        font-size: 0.75rem;
      }
    }

    .p-inputtext {
        font-size: 0.75rem;
    }
  }
}

.p-dropdown-panel {
  width: auto;
  font-size: 0.75rem;
}
