.instant-save {
    padding-top: 50px;
    text-align: center;
}

.information__block{
    display: flex;
    flex-direction: column;
    padding: 52px 74px 0 74px;
    box-sizing: border-box;

    .information__block_date{
        display: flex;
        margin-bottom: 58px;
        width: 100%;
        justify-content: space-between;

        .date__input_date, .date__input_time, .date__input_location{
            width: 32%;
        }
    }

    .information__block_personals{
        display: flex;
        flex-direction: column;
        margin-bottom: 57px;

        .personals__title{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #495057;
            margin-bottom: 14px;
        }
        .personals__block{
            display: grid;
            // flex-wrap: wrap;
            grid-template-columns: 1fr 1fr;
            gap: 12px;
            width: 100%;
            justify-content: space-between;

            & .p-autocomplete, & .p-dropdown {
              min-height: 35px;
              height: 45px;
            }

            .p-dropdown-clear-icon {
              right: 4rem;
            }

            .p-autocomplete,
            .p-autocomplete-panel, .p-dropdown-panel {
                width: 100%;
            }

            .p-autocomplete-multiple-container {
                width: 100%;
                display: flex;
                gap: 10px;
            }

            .p-autocomplete-item, .p-dropdown-item {
                text-overflow: ellipsis;
            }

            .p-dropdown-label {
                font-size: 14px;
            }

            // .personals__block_referee, .personals__block_inspector{
            //         .p-autocomplete, .p-component, .p-inputwrapper, .p-autocomplete-multiple{
            //             width: 100%;
            //             // height: 50px;
            //             .p-autocomplete-multiple-container {
            //                 // overflow-y: scroll;
            //                 .p-autocomplete-token{
            //                     margin-bottom: 0.5rem;
            //                 }
            //             }
            //         }
            //     }

            // .personals__block_medic, .personals__block_foto, .personals__block_video{
            //     width: 49%;

            //     .p-autocomplete, .p-component, .p-inputwrapper, .p-autocomplete-multiple{
            //         width: 100%;
            //     }
            // }

            // .personals__block_referee, .personals__block_inspector {
            //     width: 49%;
            //     // margin-bottom: 19px;
            // }

            .p-inputwrapper {
                ul {
                    border-color: var(--indigo-100);
                    border-right: none;
                }

                .p-autocomplete-input-token {
                    display: flex;
                    flex-wrap: wrap;
                    width: min-content;
                    min-width: min-content;
                    max-width: max-content;
                }
            }

            .p-inputwrapper-focus {
                ul {
                    box-shadow: none;
                }
            }

            .p-autocomplete-dropdown, .p-dropdown-trigger {
                //width: 60px !important;
                background: var(--indigo-50);
                color: var(--indigo-500);
                border-color: var(--indigo-100);
                border-left: none;
            }

            .p-autocomplete-token {
                font-size: 12px;
                margin-bottom: 0 !important;
            }
        }
    }

    &_media-docs {
      display: grid;
      width: 100%;
      grid-gap: 24px;
      grid-template-columns: 1fr 1fr;
    }

    .information__block_media{
        display: flex;
        flex-direction: column;

        .media__title{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #495057;
            margin-bottom: 14px;
        }

        .media__block{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;

            .media__block_foto, .media__block_live{
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .media__block_hailait{
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }

    }

    &_docs {
      display: flex;
      flex-direction: column;
      grid-gap: .5rem;

      .docs {
        &__title {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #495057;
          margin-bottom: 14px;
        }

        &__button {
          .p-fileupload-choose {
            background: none !important;
            border: none;
            color: var(--blue-700);
            padding: 0;
            font-size: 14px;
            line-height: 17px;

            &:hover {
              color: var(--blue-700) !important;
              opacity: 0.85;
            }
          }
        }

        &__block {
          display: flex;
          flex-direction: column;
          grid-gap: 16px;
        }

        &__link {
          display: flex;
          grid-gap: 1rem;
          align-items: center;
          position: relative;

          .pi {
            font-size: 24px;
            transition: .3s ease;

            @media (hover: hover) {
              &:hover {
                opacity: 0.8;
                cursor: pointer;
              }
            }
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          font-size: 14px;

          span {
            font-size: 12px;
            color: var(--bluegray-300);
          }
        }

        &__remove {
          right: 0;

          .pi {
            font-size: 16px !important;
          }
        }
      }
    }

    label{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #495057;
        margin-bottom: 7px;
    }

    .input__label{
        display: flex;
        flex-direction: column;
    }

}
