.tournamets2-teams {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
    }

    &__search {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        position: relative;

        input {
            width: 100%;
        }

        .pi-search {
            position: absolute;
            right: 40px;
        }
    }

    .p-button-danger {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 38px;
    }

    &__refresh-btn {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
      position: absolute;
      right: 64px;
      top: 16px;
      width: 38px;
    }

    &__card {
        border-radius: 10px;
        position: relative;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: 0.3s ease;

        @media (hover: hover) {
            &:hover {
                cursor: pointer;
                background: var(--Lara-Indigo-indigo-50, #f7f7fe);

                .p-button-danger, .tournamets2-teams__refresh-btn {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &-logo {
            max-width: 48px;
            height: auto;
            margin-bottom: 20px;
        }

        &-name {
            color: #495057;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 8px;
        }

        &-player {
            color: #818ea1;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.body-replace {
  display: flex;
  flex-direction: column;
  grid-gap: .5rem;

  &__team {
    display: flex;
    grid-gap: .75rem;
    align-items: center;
    width: 100%;
    padding: .75rem .75rem;
    border: 1px solid var(--bluegray-800);
    border-radius: .5rem;
    background: var(--bluegray-50);

    transition: background-color .25s;

    &:hover {
      background: var(--blue-100);
      cursor: pointer;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}
