.admin-docs {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__header {
        border-radius: 10px;
        background: #fff;
        padding: 24px;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .p-buttonset {
        box-shadow: none;
        overflow: unset;
    }

    &__button {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        grid-gap: 0.5rem;
        position: fixed;
        bottom: 1.87rem;
        right: 4.37rem;
        margin-top: 24px;
        margin-left: auto;
        z-index: 99;
    }


    &__buttons {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__dialog {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--Lara-Global-textColor, #495057);
            font-feature-settings: "liga" off, "clig" off;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
        }
    }

    .p-dialog {
        max-width: 890px;
        width: 100%;
        // height: 700px;

        &-header {
            display: flex;
            gap: 12px;
            align-items: center;

            &-icons {
                align-self: unset;
            }
        }

        &-content {
            padding: 0 !important;
            // border-radius: 10px !important;

            border-radius: 0 0 6px 6px !important;
        }

        .data-table {
            .p-datatable-table {
                border-radius: 0;
            }
            .p-paginator {
                position: static;
                border-top: 1px solid #dfe7ef;
                padding-bottom: 24px;
            }
        }
    }
}
