@media screen and (max-width: 1366px) {
    .events-requests {
        .toolbar {
            margin-top: 6px;
            margin-bottom: 12px;
        }

        .content {
            height: calc(100vh - 200px);
            justify-content: flex-start;
        }
    }
}

@media screen and (max-width: 768px) {
    .events-requests {

        .toolbar {
            padding-left: 0;

            flex-wrap: wrap;
            justify-content: center;
            padding-top: 16px;

            .p-buttonset:first-child {
                margin-bottom: 14px;
            }
        }

        .content-squads {
            width: 100%;
            margin-top: 10px;

            .request-bars {
                width: 100%;

                &>div:first-child {
                    padding: 0 12px !important;
                }
            }

            .side-notes {
                display: none;
            }

            .request-container {
                width: 100%;

                .date {
                    font-size: 14px;
                    font-weight: normal;
                }

                .request-item {
                    width: calc(100% - 16px);
                    flex-wrap: wrap;
                    padding: 10px;
                    height: unset;
                    gap: 10px;

                    .team-block {
                        width: 100%;
                        display: flex;

                        .leader, .team {
                            width: 50%;
                            box-sizing: border-box;
                            
                            .name {
                                line-height: 1.3;
                            }
                        }

                        .team {
                            padding-left: 12px;

                            .name {
                                line-height: 1.3;
                            }
                        }

                        .line {
                            display: none;
                        }
                    }

                    .meta-block, .status-block {
                        width: 45%;
                        box-sizing: border-box;
                    }

                    .meta-block {
                        padding-left: 12px;
                    }
                }
            }
        }
    }
}
