.fileupload {

    &.mediaBank {
        width: 140px !important;
        height: 40px !important;

        & .p-fileupload-choose {
            background: rgb(248, 249, 250);
        }

        & .p-fileupload-buttonbar {
            padding: 0;
            border: none;
            width: 100%;
            height: 100%;
        }
    }

    & .p-fileupload-content {
        display: none;
    }

    & .choose__loader {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 0.375rem;
        border: none;
        background: #6366F1;
        border-color: #6366F1;
        color: #ffffff;
    }
}