.report {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__btn {
        position: fixed;
        bottom: 1.87rem;
        right: 4.37rem;
        margin-left: auto;
        z-index: 9;
    }

    &__card {
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

        &-title {
            color: #495057;
            font-size: 17.5px;
            font-style: normal;
            font-weight: 800;
            line-height: 26.25px;
        }

        &-label {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }

        &-items {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        &-top {
            display: grid;
            gap: 24px;
            grid-template-columns: 1fr 1fr;
        }

        &-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .p-inputtext {
                width: 100%;
                padding: 5px 10.5px;
            }
        }
    }

    .p-buttonset {
        box-shadow: none;
        overflow: unset;
        width: 100%;
        display: flex;

        .p-button {
            width: 100%;
            border-color: #6466e9;
            color: #6466e9;

            &.p-highlight {
                color: #fff;
            }
        }
    }

    .p-autocomplete-dropdown {
        background: transparent;
        border: 1px solid #ced4da;
        border-left: 0;

        &:hover {
            background: transparent;
        }
    }

    .pi-chevron-down {
        color: #6c757d;
    }

    .p-autocomplete-multiple-container {
        gap: 10px;

        .p-autocomplete-token {
            margin: 0;
        }
    }
}

.loader-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
