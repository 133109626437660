.roles-list {
    &__row {
        color: #6466e9;
        font-size: 10.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.8px; /* 150.476% */
        border-radius: 6px;
        padding: 3.5px 5.6px;
        background: var(--Lara-Indigo-indigo-100, #dadafc);

        &.flex {
            color: var(--Lara-Yellow-yellow-600, #c79807);
            background: var(--Lara-Yellow-yellow-100, #faedc4);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;

        .p-buttonset {
            overflow: auto;
            box-shadow: unset;
            border-radius: 0;
        }

        .p-selectbutton .p-button {
            border-color: #6466e9;
            color: #6466e9;

            &.p-highlight {
                background: #6466e9;
                color: #fff;
            }
        }
    }

    &__dropdown {
        display: flex;
        align-items: center;
        gap: 14px;
        color: #FFF;
    }

    .p-dropdown-label-empty {
        visibility: visible;
    }

    .p-dropdown-trigger {
        display: none;
    }

    .p-dropdown .p-dropdown-label {
        border-radius: 6px;
        border: 1px solid #6466e9;
        background: #6466e9;
    }

    .p-dropdown-items-wrapper {
        max-height: 230px !important;
    }
}


