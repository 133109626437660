.players-and-staff-sidebar {
    &__accordion {
        &-stats {
            display: none;
            flex-direction: column;
            grid-gap: 12px;
            align-self: stretch;
        }

        &-matches {
            display: none;
            flex-direction: column;
            grid-gap: 20px;
            align-self: stretch;
        }

        &_active {
            .players-and-staff-sidebar__accordion {
                &-stats {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
                &-matches {
                    display: flex;
                }
            }
            .side-profile__accordion-doc {
                display: flex;
                padding: 1rem;
            }
        }
    }

    &__card {
        display: flex;
        padding: 24px;
        flex-direction: column;
        grid-gap: 8px;
        align-self: stretch;

        border-radius: 10px;
        background: #f7f8f9;

        &_first-stat {
            grid-column: 1 / -1;
        }

        &_empty {
            span {
                color: #bcc3cd !important;
            }
        }

        &_match {
            display: flex;
            padding: 16px;
            align-items: center;
            grid-gap: 16px;
        }

        &_squad {
            padding: 16px;
            justify-content: center;
            align-items: flex-start;
            grid-gap: 12px;
        }

        &-title {
            color: #818ea1;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        span {
            color: #6366f1;
            font-family: Inter, sans-serif;
            font-size: 24.5px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        &-teams {
            display: flex;
            align-items: center;
            align-self: stretch;
        }

        &-home,
        &-away {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;

            color: #495057;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            img {
                width: 24px;
                height: 24px;

                object-fit: contain;
            }
        }

        &-home {
            justify-content: flex-end;
            text-align: right;
        }

        &-score {
            width: 77px;
            color: #495057;
            text-align: center;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &-top {
            display: flex;
            align-items: center;
            grid-gap: 8px;
            align-self: stretch;

            padding-bottom: 12px;
            border-bottom: 1px solid #dfe7ef;

            img {
                width: 36px;
                height: 36px;

                object-fit: contain;
            }
        }

        &-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-gap: 4px;

            color: #495057;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
                font-size: 14px;
                font-weight: 400;
                color: #818ea1;
                text-align: right;
            }
        }

        &-subtext {
            display: flex;
            align-items: center;
            align-content: center;
            gap: 2px 8px;
            align-self: stretch;
            flex-wrap: wrap;

            color: #495057;
            font-variant-numeric: lining-nums tabular-nums;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            span {
                color: #9fa9b7;
                font-variant-numeric: lining-nums tabular-nums;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    &__fileupload {
        height: 100%;

        .fileupload__field {
            height: max-content !important;

            .p-fileupload,
            .fileupload__input {
                width: 50%;
                height: 223px;
            }
        }
    }

    .auth2__button_outlined {
        border-color: #6466e9 !important;

        &:hover {
            border-color: #6466e9 !important;
        }

        span {
            color: #6466e9 !important;
        }
    }

    &__duplicates {
        display: flex;
        padding: 20px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        background: #f7f8f9;

        color: #818ea1;
        font-family: "Inter V", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &-top {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            color: #495057;
            font-feature-settings: "liga" off, "clig" off;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        &-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;

            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #dfe7ef;
        }

        &_active {
            background: none;
        }

        .p-timeline-event-opposite {
            display: none;
        }
    }

    &__duplicate {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        padding: 16px;
        justify-content: center;

        border-radius: 6px;
        background: var(--Lara-Blue-Gray-bluegray-50, #f7f8f9);

        cursor: pointer;

        img {
            width: 36px;
            height: 36px;
            object-fit: contain;
        }

        &_active {
            background: #6366f1;

            img {
                border-radius: 100px;
                background: var(--Lara-Blue-blue-50, #f5f9ff);
            }

            .players-and-staff-sidebar__duplicate-body {
                color: white;

                span {
                    color: #bcbdf9;
                }
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;

            color: var(--Lara-Input-Field-inputTextColor, #495057);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
                color: var(--Lara-Blue-Gray-bluegray-400, #818ea1);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 132%; /* 18.48px */
            }
        }
    }

    &__button {
        display: flex;
        height: 24px;
        padding: 10.5px 12px;
        justify-content: center;
        align-items: center;
        gap: 14px;

        border-radius: 6px;
        background: none;
        border: 1px solid var(--Lara-Button-secondaryButtonBorder, #64748b);

        span {
            color: var(--Lara-Button-secondaryButtonBg, #64748b);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &:hover {
            background: none !important;
            color: var(--Lara-Button-secondaryButtonBg, #64748b);
            border: 1px solid var(--Lara-Button-secondaryButtonBorder, #64748b) !important;

            opacity: 0.8;
        }
    }

    &__checkbox {
        display: flex;
        align-items: center;
        gap: 9px;

        z-index: 9999;
    }

    .auth2__button-delete {
        background: #ef4444 !important;
        color: #fff;
        border-color: #ef4444 !important;
    }
}
