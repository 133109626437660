.tournamentsReqests-new {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__card {
        padding: 24px;
        box-sizing: border-box;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    }

    &__title {
        color: #495057;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px;
        margin-bottom: 12px;
    }

    &__inputs {
        display: flex;
        gap: 24px;
        justify-content: space-between;
    }

    &__notfound {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    &__label {
        color: #495057;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }

    &__template {
        display: flex;
        align-items: center;
    }

    &__user {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 25%;

        &-checkbox {
            visibility: hidden;
            opacity: 0;

            &.active {
                visibility: visible;
                opacity: 1;
            }
        }

        &-circle {
            color: #fff;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 1000px;
            background: #3d75e3;
            padding: 13.5px 12px;
            box-sizing: border-box;
            width: 40px;
            text-align: center;
        }

        &-container {
            display: flex;
            flex-direction: column;
        }

        &-fio {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-wrap: nowrap;
        }

        &-club {
            color: #818ea1;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 132%;
        }
    }

    &__dropdown {
        .body-item,
        .p-dropdown {
            width: 100%;
        }
    }
    .p-datatable-emptymessage {
        display: none !important;
    }

    &__create {
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // background: #fff;

        &-content {
            padding: 0 24px 24px;
        }
        &-btn {
            width: 100%;
        }

        .p-divider {
            margin: 8px 0 18px;
        }
    }
}

.p-autocomplete-panel {
    &.tournaments-requests-panel {
        // top: 410px !important;
        overflow: hidden;
        max-height: unset;
        height: 100%;
        padding-bottom: calc(100vh - 455px);

        .p-autocomplete-items {
            overflow: auto;
            max-height: calc(100vh - 585px);
            padding: 0;

            &::-webkit-scrollbar {
                width: 5px;
                height: 3px;
                margin-left: 30px;
            }

            &::-webkit-scrollbar-thumb {
                height: 50px;
                background-color: var(--Lara-Root-surface-border, #dfe7ef);
                border-radius: 10px;
            }
        }

        .p-autocomplete-item {
            position: static;

            &:last-of-type {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: #fff;
                padding: 0;
            }

            &:only-of-type {
                text-align: center;
                top: 50%;
            }

            &:last-of-type:hover {
                cursor: default;
                // pointer-events: none;
                background: transparent;
                // position: sticky;
                // bottom: 0;
                // left: 0;
                // right: 0;
            }
        }
    }
}
