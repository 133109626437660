.side-profile {
    width: 32% !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;

    &__overlay {
        z-index: 901 !important;
    }

    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 12px;
    }

    &__file {
        display: flex;
        align-items: center;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 12px 20px;
        justify-content: space-between;
        width: 100%;
        background: #f7f8f9;
        color: #495057;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.8px;
        gap: 20px;

        &-name {
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
        }

        &-format {
            color: #6c757d;
        }
    }

    .load-data-item__fileupload {
        width: 100%;
        &_button {
            .p-button {
                padding: 0.53125rem 1.09375rem !important;
                grid-gap: 0.875rem !important;
                transition: 0.2s !important;
            }
        }

        .p-button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.38rem;
            padding: 0.5rem;
            width: 2.38rem;
            height: 2.38rem;
            grid-gap: 0;
            width: auto;

            border: 1px solid #1b3f86;
            background: #1b3f86;
            color: white;

            background: none;
            color: #1b3f86;

            &:hover {
                background: none;
                border: 1px solid #1b3f86;

                color: #1b3f86;
                opacity: 0.8;
            }

            &-icon {
                grid-row: 1;
                grid-column: 2;
                .pi {
                    font-size: 0.75rem;
                }
            }

            &-label {
                grid-row: 1;
                grid-column: 1;
                flex: none;
                font-family: "Object Sans", sans-serif;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.0625rem;

                transition: 0s;
            }
        }
    }

    .p-accordion {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-tab {
            border-bottom: 1px solid #e9ecef !important;

            padding-bottom: 20px !important;
        }

        &-header-link {
            flex-direction: row-reverse;
            justify-content: space-between;
            background: none !important;
            border: none !important;
            padding: 0 !important;
            border-radius: 0;

            &:focus {
                box-shadow: none !important;
            }
        }

        &-content {
            border: 0;
            padding: 0;
            margin: 0;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .p-sidebar {
        &-content {
            padding: 20px 24px 0px 24px !important;
        }

        &-header {
            padding: 12px 24px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__button-delete {
        border-color: #ef4444 !important;
        padding: 8.5px;

        .p-button-label {
            color: #ef4444 !important;
        }
    }


    .fileupload__label {
        display: flex;
        flex-direction: column;

        span {
            margin: 0;
        }
    }

    .fileupload__field {
        justify-content: unset;

        .p-fileupload {
            height: 177px;
        }
    }

    .p-fileupload-choose-selected {
        display: none !important;
    }

    .load-data-item__refresh {
        .pi-refresh {
            margin: 0;
        }

        .p-button-label {
            display: none;
        }

        .p-button {
            padding: 8.5px !important;
            width: fit-content;
        }
    }

    .p-button-danger {
        padding: 8.5px;
        width: fit-content;
        height: 2.38rem;
        box-sizing: border-box;
    }
}
