.players-and-staff {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 0;

    .header__title {
        padding: 0;
    }

    .p-buttonset {
        overflow: auto;
        box-shadow: unset;
        border-radius: 0;
    }

    .p-selectbutton .p-button {
        border-color: #6466e9;
        color: #6466e9;

        &.p-highlight {
            background: #6466e9;
            color: #fff;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        color: #495057;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        & > img {
            width: 32px;
            height: 32px;
            object-fit: contain;
        }

        &-icon {
            width: 10.5px;
            height: 10.5px;
            padding: 1.125px;
        }
    }

    &__overlay {
        display: flex;
        width: 210px;
        padding: 10.5px 0px;
        flex-direction: column;
        align-items: flex-start;

        border-radius: 6px;
        background: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .p-overlaypanel-content {
            width: 100%;
        }
    }

    &__ovitem {
        display: flex;
        width: 100%;
        padding: 10.5px 17.5px;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;

        color: #495057;
        font-family: "Inter V", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        cursor: pointer;

        &:hover {
            background: #f7f8f9;
        }
    }

    .pi-info-circle {
        margin: 0;
        order: 1;
        margin-left: 0.5rem;
    }

    &__btns {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__input {
        display: flex;
        align-self: stretch;
        width: 100%;

        .p-button {
            width: 100%;
            justify-content: center;
        }
    }

    &__tag {
        padding: 3.5px 5.6px;
        border-radius: 6px;
        background: #f5f9ff;
        color: #6466e9;
        font-family: Inter;
        font-size: 10.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.5px;
    }

    &__phone {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    &__no {
        color: var(--Lara-Blue-Gray-bluegray-400, #818ea1);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 132%; /* 18.48px */
    }
}

.players-tooltip {
    max-width: 392px;
    width: 100%;
    .p-tooltip-text {
        color: var(--Lara-Tooltip-tooltipTextColor, #fff);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
    }
}

.players-dialog {
    border-radius: 18px;
    background: var(--Lara-Button-buttonTextColor, #fff);
    box-shadow: 0px 4px 30px 0px rgba(59, 130, 246, 0.12);
    overflow: hidden;
    max-width: 505px;
    width: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .p-dialog-header {
        padding-top: 36px;
    }

    .p-dialog-title {
        color: var(--Lara-Global-textColor, #495057);
        font-family: Inter;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
    }

    &__req {
        display: flex;
        flex-direction: column;
        gap: 6px;
        color: var(--Lara-Blue-Gray-bluegray-400, #818ea1);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 16px;
        border-radius: 12px;
        background: var(--Lara-Blue-Gray-bluegray-50, #f7f8f9);

        &-title {
            color: var(--Lara-Global-textColor, #495057);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 6px;
        }

        ul {
            margin: 0;
            list-style-type: disc;
            padding-left: 25px;
        }
    }
}
