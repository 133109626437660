.tournamentsReqests-processed {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__accepted {
    color: #1ea97c;
  }

  &__rejected {
    display: flex;
    align-items: center;
    color: #ef4444;
    gap: 5px;

    .pi-info-circle {
      font-size: 10.5px;
      color: #818ea1;
    }
  }

  &__squad {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 7px;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  &__squads {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__template {
    align-items: center;
    display: grid;
    grid-template-columns: 39%;
    grid-auto-flow: column;

    &_notactive {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 8px;

    &-checkbox {
      visibility: hidden;
      opacity: 0;

      &.active {
        visibility: visible;
        opacity: 1;
      }
    }

    &-circle {
      color: #fff;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 1000px;
      background: #3d75e3;
      padding: 13.5px 12px;
      box-sizing: border-box;
      width: 40px;
      text-align: center;
    }

    &-container {
      display: flex;
      flex-direction: column;
    }

    &-fio {
      color: #495057;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-wrap: nowrap;
    }

    &-club {
      color: #818ea1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 132%;
    }
  }

  &__create {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // background: #fff;

    &-content {
      padding: 0 24px 24px;
    }

    &-btn {
      width: 100%;
    }

    .p-divider {
      margin: 8px 0 18px;
    }
  }

  &__team {
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

    &-req {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &-logo {
      width: 64px;
      height: 64px;
    }

    &-item {
      display: flex;
      flex-direction: column;
    }

    &-muted {
      color: #818ea1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &-title {
      color: #495057;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 34px;
    }
  }

  &__tour {
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

    &-logo {
      width: 64px;
      height: 64px;
    }

    &-item {
      display: flex;
      flex-direction: column;
    }

    &-muted {
      color: #818ea1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &-title {
      color: #495057;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 34px;
    }

    &-text {
      color: #495057;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .data-table .p-datatable-table {
    border-radius: 0 0 0.625rem 0.625rem;
  }

  &__timeline_container {
    width: 100%;
    margin: 0;
    padding: 0;

    .p-timeline {
      flex-grow: unset;
      max-width: 100%;
      width: 100%;
      margin-top: 40px;

      .p-timeline-event {
        max-width: 100%;
        width: 100%;
      }

      .p-timeline-event-content {
        max-width: 100%;
        margin-top: -24px;
      }

      .p-timeline-event-opposite {
        padding-left: 0;
        display: none;
      }
    }
  }

  &__timeline {
    padding: 0.25rem 0 0 1.25rem;
    align-items: flex-start;
    width: 100%;

    .custom-marker-red {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 0.875rem;
      height: 0.875rem;

      background: #FFFFFF;

      border: 0.125rem solid #FF3D32;
      border-radius: 1.687rem;
    }

    .custom-marker-blue {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 0.875rem;
      height: 0.875rem;

      background: #FFFFFF;

      border: 0.125rem solid #3B82F6;
      border-radius: 1.687rem;
    }

    .timeline-event {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      padding: 16px 20px;
      align-items: flex-start;
      align-self: stretch;

      border-radius: 8px;
      background: #FAFAFA;

      .date {
        color: #495057;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        width: 100%;
        display: flex;
        justify-content: space-between;

        //flex: none;
        //order: 0;
        //flex-grow: 1;
        &.handled {
          color: #F59E0B;
        }

        span {
          color: #9FA9B7;
          font-family: "Inter V", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .name {
        color: #6C757D;
        font-family: "Inter V", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        flex: none;
        order: 0;
        flex-grow: 1;
      }
    }
  }

  & .clubs-detail__buttons {
    display: flex;
    position: fixed;
    bottom: 1.87rem;
    right: 4.37rem;
    margin-top: 24px;
    margin-left: auto;

    & .clubs-detail__button {
      position: static;
    }
  }
}

.column__ban {
  border-radius: 6px;
  background: #495057;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: fit-content;

  &-title {
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    line-height: 21px;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-text {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    &.muted {
      color: #bdbdbd;
    }
  }

  &-btns {
    display: flex;
    align-items: center;
    gap: 8px;

    .p-button {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.p-autocomplete-panel {
  &.tournaments-requests-panel-processes {
    padding-bottom: calc(100vh - 515px);

    .p-autocomplete-items {
      max-height: calc(100vh - 645px);
    }
  }
}