.renders {
    @media screen and (min-width: 768px) {
        overflow-y: auto;
        max-height: calc(100vh - 86px);
    }

    .content {
        width: 100%;
        position: relative;
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: .75rem;
        align-items: center;
        box-sizing: border-box;

        .p-accordion {
            width: 100%;

            & .p-accordion-header-link {
                border: none;
                padding: 16px 24px;
                flex-direction: row-reverse;
                justify-content: space-between;
                gap: 20px;
            }

            & .p-accordion-header-text {
                display: flex;
                align-items: center;
                gap: 20px;
                flex: 1 0 auto;
            }

            & .p-accordion-toggle-icon {
                color: #343A40;

            }

            & .p-accordion-tab {
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
                
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }

            & .p-accordion-title {
                color: var(--Lara-Global-textColor, #495057);
                font-family: Inter;
                font-size: 17.5px;
                font-weight: 800;
                line-height: 14px; /* 80% */
            }

            & .p-accordion-content {
                padding: 0;
            }
        }

        &-stages {

            & .p-tabview-panels {
                padding: 0;
            }

            & .p-tabview-nav {
                padding-inline-start: 0;
            }

            & .p-tabview-ink-bar {
                display: none;
            }

            &__item {
                padding: 24px;

                & .p-button {
                    padding: 10.5px 17.5px;
                    color: var(--Lara-Global-textSecondaryColor, #6C757D);
                    background: var(--Lara-Indigo-indigo-50, #F7F7FE);
                    border-radius: 6px;
                    border: 1px solid transparent;
                    transition: .3s ease;
                    
                    @media (hover: hover) {
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }

                    & .p-button-label {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                    }

                    &.active {
                        color: var(--Lara-ToggleButton-toggleButtonTextActiveColor, #FFF);
                        border: 1px solid var(--Lara-Button-buttonBg, #6466E9);
                        background: var(--Lara-Button-buttonBg, #6466E9);
                    }
                }

                &-block {
                    padding-bottom: 24px;
                    margin-bottom: 24px;
                    border-bottom: 1px solid var(--Lara-Root-surface-border, #DFE7EF);
                }

                &-title {
                    color: var(--Lara-Global-textColor, #495057);
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px; /* 100% */
                    margin-bottom: 10px;
                }

                &-buttons {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;    
                    
                    
                    & .p-calendar {
                        // min-width: 250px;
                        transition: .3s ease;

                        input {
                            box-shadow: none;
                            border-right: none;
                            padding: 8.5px 0 8.5px 17.5px;
                            background: var(--Lara-Indigo-indigo-50, #F7F7FE);
                            color: var(--Lara-Global-textSecondaryColor, #6C757D);
                            font-family: Inter;
                            font-size: 14px;
                            text-align: center;
                            font-weight: 400;
                            line-height: normal;
                            border: none;
                            transition: .3s ease !important;

                            &::placeholder {
                                transition: .3s ease;
                            }
                        }

                        & .p-button.p-datepicker-trigger {
                            padding-left: 14px;
                            width: auto;
                            border-radius: 0 6px 6px 0;
                            transition: .3s ease !important;
                        }

                        &:hover {
                            opacity: 0.8;
                            cursor: pointer;
                        }

                        // &:hover,  , &.p-inputwrapper-focus
                        &.active {
                            .p-button.p-datepicker-trigger {
                                background: #6466E9;
                                color: #BCBDF9;

                                &:hover {
                                    opacity: 1;
                                }
                            }

                            input {
                                background: #6466E9;
                                color: var(--Lara-Surface-surface-0, #FFF);

                                &::placeholder {
                                    color: var(--Lara-Surface-surface-0, #FFF);
                                }
                            }

                        }
                    }
                }

                &-checkBtn {
                    width: 100%;
                    justify-content: center;

                    & .p-button-label {
                        flex: 0 0 auto;
                    }
                }
            }
        }
    }
}
