.data-table {
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    &.search,
    &.autoComplete {
        .p-datatable-tbody {
            padding-top: 76px;
        }
    }

    .p-datatable-wrapper {
        height: max-content;
        overflow: hidden;
        width: 100%;
    }

    .p-datatable-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: max-content;
        width: 100%;
        overflow: hidden;
        border-radius: 0.625rem;
    }

    .p-datatable-thead {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;

        tr {
            display: flex;
            grid-gap: 0.625rem;
            align-self: stretch;
            width: 100%;
            background: var(--Lara-Blue-blue-100, #dadafc);
            padding: 0 1.5rem;
        }

        th {
            background: rgb(0, 0, 0, 0);
            background-color: #dadafc !important;
            padding: 0 !important;
            width: 100%;

            &.homeTeam {
                display: flex;
                justify-content: flex-end;
            }

            &.score {
                width: 25%;
            }

            &.secondary {
                width: 35%;
            }

            &.tag {
                width: 10%;
            }

            &.actions {
                width: 25%;
            }
        }
    }

    .p-inputtext {
        width: 100%;
    }

    .p-column-header-content {
        display: flex;
        // padding: 1rem 0 1rem 1.5rem;
        padding: 1rem 0 1rem 0;
        align-items: flex-start;
        // grid-gap: 0.4375rem;
        align-self: stretch;
        background: var(--Lara-Blue-blue-100, #dadafc);

        span {
            color: #6466e9;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 21px; /* 150% */

            svg {
                color: #4070cd;
            }
        }
    }

    .p-datatable-tbody {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: max-content;
        width: 100%;

        tr {
            display: flex;
            padding: 0 1.5rem;
            grid-gap: 0.625rem;
            align-self: stretch;
            width: 100%;
            min-height: 62px;

            border-bottom: 1px solid #dfe7ef;
            background: #fff;

            &:hover {
                cursor: pointer;
                background: var(--Lara-Blue-blue-50, #f5f9ff);

                .data-table__action {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &:last-child {
                border: 0;
            }

            td {
                display: flex;
                width: 100%;
                align-items: center;
                border: none !important;
                padding: 0 !important;
                overflow: hidden;
                &.secondary {
                    width: 35%;
                }

                &.homeTeam {
                    justify-content: flex-end;
                }

                &.score {
                    width: 25%;
                    justify-content: center;
                }

                &.tag {
                    width: 10%;

                    & .body-item {
                        display: flex;

                        .partnersForm-list__isVisible {
                            color: var(--Lara-Blue-blue-500, #3d75e3);
                            font-family: "Object Sans";
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            padding: 2px 8px;
                            border-radius: 6px;
                            background: #f2f7ff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 25px;
                        }
                    }
                }

                &.actions {
                    width: 25%;
                }
            }

            &.p-datatable-emptymessage {
                align-items: center;
                height: 50vh;
                justify-content: center;

                td {
                    justify-content: center;
                    border: 0;
                }
            }
        }

        td {
            color: #495057;
            font-family: "Object Sans", sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3125rem;
        }
    }

    &__empty {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 0.75rem;
    }

    &__search {
        position: absolute;
        left: 0;
        top: 53px;
        width: 100%;
        background: #fff !important;
        border-bottom: 1px solid var(--Lara-Root-surface-border, #dfe7ef);

        .input-container .input {
            color: var(--Lara-Input-Field-inputPlaceholderTextColor, #6c757d);
            font-family: "Object Sans";
            /* font-size: 14px; */
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }

        .p-column-header-content {
            display: flex;
            padding: 1rem 1.5rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;
            background: #fff;

            span {
                width: 100%;
            }

            .p-column-title {
                position: relative;
            }

            .pi-search {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                color: #6c757d;
                pointer-events: none;
            }
        }
    }

    &__reorder {
        display: flex;
        height: 100%;
        width: 5% !important;
        align-items: center;
    }

    &__action {
        visibility: hidden;
        opacity: 0;
        display: flex;
        align-items: center;
        width: 40px !important;
        min-width: 40px !important;
        transition: 0.3s ease;

        &-download {
            padding: 8.5px;
            border-radius: 6px;
            border: 1px solid var(--Lara-Button-dangerButtonBorder, #6466e9);
            color: #6466e9;

            .pi {
                color: #6466e9;
                vertical-align: middle;
            }
        }

        &-remove {
            padding: 8.5px;
            border-radius: 6px;
            border: 1px solid var(--Lara-Button-dangerButtonBorder, #e50076);
            color: #e50076;

            .pi {
                color: #e50076;
                vertical-align: middle;
            }
        }

        img {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }

        &-overlay {
          display: flex;
          padding: 10.5px 17.5px;
          align-items: flex-start;
          align-self: stretch;
          cursor: pointer;

          &:hover {
            opacity: .8;
          }
        }
    }

    &__angle {
        justify-content: flex-end;
        width: 20%;
    }

    .p-column-filter-menu {
        margin-left: 10px;
    }

    .body-item,
    .mediaForm-list__date,
    .mediaForm-list__title,
    .body-item__default {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-pack: center;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
        height: fit-content;
    }

    .p-paginator {
        position: absolute;
        bottom: -94px;
        width: 100%;
        border: 0;
        background: transparent;
    }

    .p-column-filter-menu-button {
        overflow: visible;
        height: 10px;
        background: transparent !important;
        box-shadow: unset !important;
    }
}

.p-column-filter-buttonbar {
    gap: 10px;
    .p-button {
        padding: 10.5px 32px !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
