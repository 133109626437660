.reg-success-kind {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  grid-gap: 2rem;

  &__subtitle {
    color: #495057;
    font-family: Inter, sans-serif;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
  }

  &__cards {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 1.5rem;
    align-self: stretch;
  }

  &__card {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    grid-gap: 1.25rem;

    border-radius: .625rem;
    background: #F7F8F9;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 1.125rem;
      align-self: stretch;
    }

    &-header {
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      align-self: stretch;
    }

    &-title {
      color: #495057;
      font-family: Inter, sans-serif;
      font-size: .875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.05rem;
      white-space: pre-wrap
    }

    &-body {
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 1.5rem;
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
    }

    &-text {
      color: #495057;
      font-family: "Inter V", sans-serif;
      font-size: .875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}