.salary {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    position: relative;

    border-radius: 6px;
    background: #f7f8f9;
    transition: 0.3s ease;

    @media (hover: hover) {
        &:hover {
            background: #f7f7fe;
            cursor: pointer;

            .salary__delete {
                visibility: visible;
                opacity: 1;
            }

            .salary__date {
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    .salary__delete {
        position: absolute;
        background: transparent !important;
        color: #ef4444 !important;
        border-color: #ef4444 !important;
        right: 16px;
        top: 16px;
        width: 38px !important;
        height: 38px;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    &__bill {
        color: #6366f1;
        font-feature-settings: "liga" off, "clig" off;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    &__text {
        width: 100%;
        color: #495057;
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__title {
        color: #6366f1;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &__date {
        color: #9fa9b7;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        transition: 0.3s;
    }

    &__showall {
        cursor: pointer;
        color: #818ea1;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__container {
        padding-top: 10px;
        border-top: 1px solid #dfe7ef;
        width: 100%;
    }

    &__team {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #818ea1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        & > img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: contain;
            background: #fff;
        }

        &-only {
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    &__teams {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #818ea1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: contain;
            background: #fff;

            &:not(:first-child) {
                margin-left: -2px;
            }
        }
    }

    &__tooltip {
        border-radius: 6px;
        background: #495057;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        min-width: 282px;
        max-height: 171px;
        overflow-y: auto;
        transform: translateY(5%);

        &::-webkit-scrollbar {
            width: 4px;
            height: 1px;
        }

        &::-webkit-scrollbar-thumb {
            height: 5px;
            background-color: #9e9e9e;
            border-radius: 10px;
        }

        .salary__team {
            color: #fff;
            margin-bottom: 6px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
