.live-panel {
    backdrop-filter: blur(3px);
    animation: panel-enter-animation 150ms forwards !important;

    &.request-panel {
        .p-sidebar-header {
            display: none !important;
        }
    }

    .p-sidebar {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        background: linear-gradient(180deg, #F5F5FD 0%, #FFFFFF 69.79%);
        box-shadow: 0px 1px 50px rgba(99, 102, 241, 0.09);
        position: relative;
        height: unset !important;
        padding-bottom: 0px;
        max-height: 90vh;

        .p-sidebar-header {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 16px;
            z-index: 10;
        }
    }

    .panel-title {
        font-weight: 600;
        padding-top: 38px;
        font-size: 1rem;
        color: var(--bluegray-700);
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .p-inputgroup.minute-control {
            margin-top: .5rem;
            display: inline-flex;
            width: 10rem;
            margin-right: .75rem;

            .p-inputgroup-addon {
                padding: .35rem;
                min-width: unset;
                font-size: .8rem;
            }

            .p-inputnumber {

                input {
                    font-size: .8rem;
                    padding: .35rem;
                }
            }
        }
    }

    .panel-subtitle {
        font-size: 13px;
        margin-top: 8px;
        color: var(--bluegray-500);
    }

    .panel-section {

        .panel-title {
            font-size: 14px;
            color: var(--bluegray-600);
            padding-bottom: 8px;
            border-bottom: 1px solid var(--bluegray-100);
            margin-bottom: 12px;
        }

        .p-button {
            margin-top: 12px;

            &.p-button-secondary {
                background-color: var(--bluegray-50);
                color: var(--bluegray-500);
            }
        }
    }

    .panel-loader {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .panel-icon {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10;
        top: -42px;

        img {
            height: 64px;
        }
    }

    .panel-action {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 1px 1px 22px rgba(0, 0, 0, 0.06);
        z-index: 99;

        .panel-action-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 12px;
        }

        .p-button {
            width: 100%;
            justify-content: center;
            transition: all .4s ease-in-out;
            white-space: nowrap;

            .pi {
                margin-right: 8px;
            }

            &.p-button-secondary, &.p-button-secondary:focus {
                background: var(--bluegray-50);
                color: var(--bluegray-600);
                border-color: transparent;
            }

            &.p-button-warning {
                background: var(--orange-50);
                color: var(--orange-400);
                border-color: transparent;
            }

            &.p-button-danger {
                background: var(--red-50) !important;
                color: var(--red-500) !important;
                border-color: transparent !important;
            }
        }
    }

    .p-sidebar-content {
        padding: 0 14px;
    }
}

@keyframes panel-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(255, 255, 255, 0.75);
  }
}

@import './styles/control-request.scss';
@import './styles/club-request.scss';
@import './styles/player-form.scss';
@import './styles/staff-item.scss';
@import './styles/locations.scss';
