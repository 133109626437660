.tournamets2-disqualification {
    &__header {
        background: #f7f7fe;
        width: 100%;
        border-radius: 10px 10px 0 0;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-btn {
            padding: 17.5px;
            color: #6c757d;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border-radius: 6px 6px 0px 0px;
            transition: 0.3s ease;

            @media (hover: hover) {
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }

            &.active {
                background: #fff;
                color: #495057;
            }
        }
    }

    &__notfound {
        color: #6c757d;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: calc(50vh - 150px);
    }

    &__user {
        display: flex;
        align-items: center;
        gap: 8px;

        &-circle {
            color: #fff;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 1000px;
            background: #3d75e3;
            padding: 13.5px 12px;
            box-sizing: border-box;
            width: 40px;
            text-align: center;
        }

        &-container {
            display: flex;
            flex-direction: column;
        }

        &-fio {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &-club {
            color: #818ea1;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 132%;
        }
    }

    &__calendar {
        &.p-calendar {
            width: 130px;
        }

        .p-button {
            background: white;
            border: 1px solid #ced4da;
            color: #6c757d;
        }
    }

    .p-inputnumber {
        input {
            width: 130px;
        }
    }
}
