.input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-gap: 0.63rem;
    width: 100%;

    &__label {
        color: var(--Lara-Global-textColor, #495057);
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
    }

    &__description {
        display: flex;
        padding: 1rem 0.65625rem;
        flex-direction: column;
        grid-gap: 0.125rem;
        align-self: stretch;
        margin-top: -0.63rem;

        border-radius: 0 0 0.375rem 0.375rem;
        background: var(--lara-root-surface-hover);

        color: var(--lara-global-textsecondarycolor);
        font-family: var(--font-family);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.05rem;
    }

    .input {
        display: flex;
        padding: 0.65625rem;
        align-items: flex-start;
        grid-gap: 0.875rem;
        align-self: stretch;
        width: 100%;

        border-radius: 0.375rem;
        border: 1px solid var(--lara-input-field-inputborder);
        background: var(--lara-input-field-inputbg);

        color: var(--Lara-Action-Icons-actionIconHoverColor, #343A40);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        &_invalid {
            border: 1px solid var(--lara-input-field-inputerrorbordercolor);
        }

        input {
            border-radius: 0;
            color: var(--Lara-Global-textColor, #495057) !important;
            font-family: var(--font-family);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 19px !important;
        }
    }
}

.p-calendar.p-focus > .p-inputtext {
    box-shadow: none !important;
}

.p-monthpicker,
.p-yearpicker {
    &-month,
    &-year {
        padding: 1rem 0;

        &:focus {
            box-shadow: none;
        }

        &.p-highlight {
            background: #f2e1e1;
            color: var(--lara-theme-highlighttextcolor);
        }
    }
}

.p-datepicker {
    // max-width: 350px !important;
    // min-width: unset !important;

    &-trigger {
        color: rgba(108, 117, 125, 1);
        position: absolute;
        right: 10.72px;
        width: unset;
    }

    &-header {
        padding: 1rem;
    }

    &-calendar-container {
        padding: 1rem;
    }

    &-title {
        display: flex;
        grid-gap: 0.75rem;
    }

    table td > span.p-highlight {
        background: #f2e1e1;
        color: var(--lara-theme-highlighttextcolor);
    }
}

.p-link {
    &:hover {
        color: var(--lara-input-switch-inputswitchslideronbg);
    }
}
