.event-panel {
  overflow: hidden;
  max-height: none;

  &__minutes {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  &__input {
    display: flex;
    width: calc(50% - 8px);
    padding: 10.5px !important;
    align-items: flex-start;
    gap: 14px;
    flex: 1 0 0;

    border-radius: 6px;
    border: 1px solid var(--Lara-Input-Field-inputBorder, #CED4DA);
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;

    color: #465161;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    padding-top: 24px;
    border-top: 1px solid #DFE7EF;
  }

  &__types {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  &__type {
    display: flex;
    height: 38px;
    padding: 8.5px 17.5px;
    justify-content: center;
    align-items: center;
    gap: 14px;

    border-radius: 6px;
    background: #F7F7FE !important;
    border: none !important;

    color: #6C757D !important;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &_active {
      color: #FFF !important;
      border: 1px solid var(--Lara-Button-buttonBg, #6466E9) !important;
      background: var(--Lara-Button-buttonBg, #6466E9) !important;
    }
  }

  &__subtypes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    align-self: stretch;
  }

  &__button {
    display: flex;
    padding: 8.5px 17.5px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    flex: 1 0 0;

    border-radius: 6px;
    background: #F7F7FE;
    border: none;

    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;


    .p-button-icon {
      font-size: 10px;
      margin-right: 0 !important;
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

    .panel-action {
        flex-direction: row;
        flex-wrap: wrap;

        .p-button {
            width: 49% !important;
        }

        .p-button-info {
            width: 100% !important;
            margin-bottom: 8px;
        }

        .p-button:last-child {
            margin-left: 2%;
        }
    }

    .section-badge {
        .p-badge.p-badge-info {
            background: var(--blue-100);
            color: var(--blue-500);
        }
    }

    .p-badge.minute-badge {
        background: var(--bluegray-100);
        color: var(--bluegray-500);
        margin-top: -5px;
        margin-right: 6px;
    }

    .schemas-btns {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        padding: 12px 0 66px;

        .p-button {
            margin: 8px 4px;
        }
    }

    .roster-squad {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
    }

    .event-options {
        padding-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 14px;

        .field-radiobutton {
            white-space: nowrap;
        }

        .p-radiobutton {
            margin-right: 8px;

            .p-highlight {
                background-color: var(--blue-500) !important;
                border-color: var(--blue-500) !important;
            }
        }
    }
}
