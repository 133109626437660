.location-matches {
    &__tournament {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &-text {
            color: #495057;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-pack: center;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            box-orient: vertical;
            height: -moz-fit-content;
            height: fit-content;
        }

        span {
            color: #9fa9b7;
        }
    }

    &__team {
        display: flex;
        align-items: center;
        grid-gap: 8px;

        color: #495057;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &_home {
            justify-content: flex-end;
            text-align: right;
        }

        &_away {
            img {
                order: -1;
            }
        }

        img {
            height: 24px;
            width: 24px;
            object-fit: contain;
        }
    }

    &__score {
        color: #495057;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__date {
        justify-content: flex-end;
        text-align: right;
    }
}
