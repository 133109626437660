.tournamentsV2-list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  th {
    width: 100%;
  }

  &__table-item {
    display: flex;
    align-items: center;
    gap: 20px;

    & > img {
      width: 48px;
      height: 48px;
    }
  }
}