.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 2rem;

  &__line {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: .75rem;
    align-self: stretch;

    color: #9FA9B7;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
  }
}
