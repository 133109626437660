.custom-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;

    &__btns {
        display: flex;
        border: 1px solid #6466e9;
        background: #6466e9;
        border-radius: 6px;
        overflow: hidden;
        height: 100%;
    }

    &__btn {
        color: #fff;
        padding: 8.5px;
        transition: 0.3s ease;

        @media (hover: hover) {
            &:hover {
                background: #4e46dd;
                cursor: pointer;
            }
        }
    }

    &__label {
        color: #495057;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__today {
        border-radius: 6px;
        border: 1px solid #6466e9;
        background: #6466e9;
        color: #fff;
        padding: 10.5px 17.5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        transition: 0.3s ease;

        @media (hover: hover) {
            &:hover {
                background: #4e46dd;
                cursor: pointer;
            }
        }
    }
}
