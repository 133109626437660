.clubs-teams {
    &__active {
        color: var(--Lara-Button-buttonBg, #6466e9);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 175% */
        padding: 2px 8px;
        border-radius: 6px;
        background: var(--Lara-Indigo-indigo-50, #f7f7fe);
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

}
