.cookies {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  position: fixed;
  left: 0;
  bottom: 1.5rem;

  z-index: 100001;

  .cookies-wrapper {
    display: flex;
    max-width: 90%;
    padding: 1rem 1.5rem;
    grid-gap: 1.5rem;
    align-items: center;
    border-radius: .375rem;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);

    @media screen and (max-width: 600px) {
      display: flex;
      padding: 1.5rem 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      grid-gap: .75rem;
    }

    .cookies-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      color: var(--lara-global-text-color);
      font-size: .875rem;
      font-style: normal;
      font-weight: 800;
      line-height: 1.3125rem;

      .cookies-text-secondary {
        color: #818EA1;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem;

        .cookies__link {
          cursor: pointer;

          color: #152440;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.3125rem;

          text-decoration: none;
        }
      }
    }

    .p-button {
      display: flex;
      padding: 11px 18px;
      justify-content: center;
      align-items: center;
      grid-gap: 0.875rem;
      overflow: visible;
      text-wrap: nowrap;
      border-radius: 0.375rem;
      border: 1px solid #152440;
      background: #152440;
      align-self: center;

      color: #FFF;
      font-size: .875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.0625rem;

      @media screen and (max-width: 600px) {
        align-self: stretch;
      }
    }
  }
}