.tournamets2-calendar-list {
    &__row {
        border-radius: 6px;
        padding: 3.5px 5.6px;
        font-size: 10.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.8px;

        &.group {
            color: #6466e9;
            background: #dadafc;
        }

        &.playoff {
            color: #119C8D;
            background: #C7EEEA;
        }

        &.circle {
            background: #FAEDC4;   
            color: #C79807;
        }

        &.finished {
          background: #F7F8F9;
          color: #818EA1;
        }
    }
}
