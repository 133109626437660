.tournaments2 {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    white-space: nowrap;

    .p-buttonset {
      box-shadow: none;
      overflow: unset;
    }

    @media (max-width: 1600px) {
      .p-button {
        padding: .5rem;
      }
    }
  }

  &__btn-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .p-listbox {
      position: absolute;
      top: 3rem;
      right: 0;
      min-width: 300px;

      z-index: 99999999;
    }
  }
}
