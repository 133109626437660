.calendar-item {

  &_empty {
    display: flex;
    height: 570px;
    padding: 24px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 24px;
    align-self: stretch;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);
  }

  &__container {
    display: flex;
    width: 278px;
    flex-direction: column;
    align-items: center;
    grid-gap: 24px;

    color: #6C757D;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &-btn-set {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
    }

    &-btn {
      display: flex;
      padding: 10.5px 32px;
      justify-content: center;
      align-items: flex-start;
      gap: 14px;
      align-self: stretch;
      white-space: nowrap;

      &_outlined {
        border-radius: 6px;
        border: 1px solid #6466E9;
        background: none;
        color: #6466E9;
      }
    }
  }

  &__unassigned {
    display: flex;
    padding: 24px;
    align-items: center;
    grid-gap: 12px;
    align-self: stretch;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);

    color: #818EA1;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  &__row {
    gap: 24px;
    align-self: stretch;

    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}
