.calendar-item {

  &_empty {
    display: flex;
    height: 570px;
    padding: 24px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 24px;
    align-self: stretch;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);
  }

  &__container {
    display: flex;
    width: 278px;
    flex-direction: column;
    align-items: center;
    grid-gap: 24px;

    color: #6C757D;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &-btn-set {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
    }

    &-btn {
      display: flex;
      padding: 10.5px 32px;
      justify-content: center;
      align-items: flex-start;
      gap: 14px;
      align-self: stretch;
      white-space: nowrap;

      &_outlined {
        border-radius: 6px;
        border: 1px solid #6466E9;
        background: none;
        color: #6466E9;
      }
    }
  }

  &__unassigned {
    display: flex;
    padding: 24px;
    align-items: center;
    grid-gap: 12px;
    align-self: stretch;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);

    color: #818EA1;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  &__row {
    gap: 24px;
    align-self: stretch;

    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  &__mday {
    display: flex;
    padding: 36px 0;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(50% - 12px);
    grid-gap: 24px;
    flex: 1 0 0;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);

    &-header {
      display: flex;
      width: 100%;
      height: 38px;
      padding: 0px 36px 24px;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      align-self: stretch;

      border-bottom: 1px solid #DFE7EF;

      color: #495057;
      font-family: Inter, sans-serif;
      font-size: 17.5px;
      font-style: normal;
      font-weight: 800;
      line-height: 26.25px;
    }

    &-matches {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
    }

    &-match {
      display: flex;
      padding: 0 36px;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      &_editor {
        gap: 0;
      }

      .p-dropdown {
        flex: 1 0 0;
        width: calc(50% - 79px);
        min-height: 47px;

        img {
          width: 20px;
          height: 20px;
        }

        .p-dropdown-label-empty {
          visibility: visible;
        }
      }
    }

    &-team {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 7px;
      flex: 1 0 0;

      border-radius: 6px;
      border: 1px solid rgba(206, 212, 218, 0.00);

      width: calc(50% - 79px);

      &_editor {
        justify-content: flex-start;
        width: 100%;
        color: #6C757D;
      }

      &-name {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        color: #495057;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }

      img {
        width: 36px;
        height: 36px;
        object-fit: cover;
      }
    }

    &-score {
      display: flex;
      height: 62px;

      width: 79px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 4px;
      background: #F7F8F9;

      color: #6C757D;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;

      &_editor {
        background: none;
      }
    }
  }
}
