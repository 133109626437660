.club__request_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .club__request_title {
        text-align: center;
    }
}
