.reg-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 2rem;

  &__modal {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1.25rem;
  }
}

.p-dialog-header {
  text-align: start !important;
}