.breadcrumbs {

    @media (max-width: 768px) {
        padding: 16px 24px 0;
        margin: 0;
        border-radius: 0;
    }

    &__container {
        border-radius: 10px;
        background: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }


    &__nav {
        display: flex;
        gap: 20px;
        align-items: center;
        overflow-x: scroll;
        text-wrap: nowrap;

        @media (max-width: 480px) {
            gap: 16px;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    &__item {
        color: #fff;
        font-size: 16.6px;
        font-weight: 450;
        line-height: 20px;
        letter-spacing: -0.083px;
        padding: 14px 0;
        transition: 0.3s ease;

        @media (any-hover: hover) {
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }

        @media (max-width: 480px) {
            padding: 12px 0;
            font-size: 13.8px;
            line-height: 16px;
        }

        &_active {
            color: var(--brand-menu-gold, #d2b37c);
            border-bottom: 4px solid var(--brand-menu-gold, #d2b37c);
        }
    }

    &__crumbs {
        display: flex;
        gap: 8px;
    }

    &__crumb {
        display: flex;
        gap: 8px;
        color: var(--Lara-Blue-Gray-bluegray-400, #9FA9B7);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px; /* 150% */

        // &::after {
        //     content: "";
        //     display: block;
        //     height: 12px;
        //     width: 12px;
        //     background: url(../../../assets/img/arrow-w.svg) center/cover no-repeat;
        // }

        &:last-child {
            .breadcrumbs__more {
                display: none;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        @media (max-width: 650px) {
            flex-wrap: wrap;
            margin-bottom: 40px;

            .breadcrumbs__title {
                margin: 0;
            }

            img {
                margin: 0 auto;
            }
        }
    }

    .pi-pencil:before {
        color: #9fa9b7;
        font-size: 14px;
        cursor: pointer;
    }

}
