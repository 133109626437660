.tactic__img_block{
    width: 40px;
    height: 40px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 11px;
    box-sizing: border-box;
    transition: 0.2s;

    .pi-clock{
        color:#9FA9B7
    }
    .pi-user{
        color:#FFFFFF
    }

    &.empty{
        background: #F7F8F9;
    }
    &.active{
        background: #6366F1;
    }
    &.fill {
        background: #CAF1D8;
        color: #1DA750;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .tactic-num {

        }

        .tactic-surname {
            display: inline-flex;
            align-items: center;
            justify-content: space-around;
            align-self: center;
            //position: absolute;
            background: #F4FCF7;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            color: #37404C;
            padding: 2px 5px;
            //bottom: 0;
            z-index: 2;
        }
    }
}
