.non-activity {
    .rbc-today {
        background: #dadafc;
    }

    .rbc-off-range-bg {
        background: transparent;
    }

    .rbc-off-range {
        .rbc-button-link {
            color: inherit !important;
        }
    }

    .rbc-day-bg {
        transition: 0.3s ease;
        @media (hover: hover) {
            &:hover {
                background: #dee2e6;
                cursor: pointer;
            }
        }
    }

    .rbc-button-link {
        color: #495057;
        text-align: right;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
    }

    .rbc-event {
        border-radius: 6px;
        background: #6466e9;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding: 4px;
    }

    .rbc-month-row {
        background: #fff;
    }

    .rbc-header {
        color: #343a40;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        background: #f8f9fa;
        &::first-letter {
            text-transform: uppercase;
        }
    }
}
