@media screen and (max-height: 650px) {

    .switcher {

        .emblem.md {
            width: 44px;
            height: 44px;
        }

        .subject-name {
            font-size: 0.87rem;
        }

        .subject-descriptor {
            font-size: 0.81rem;
        }
    }
}
