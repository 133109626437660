.clubs-list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 32px 24px;
    }

    .p-dropdown {
        border-color: #6466e9;
        max-width: 350px;
        width: 100%;

        &-panel {
            width: 100%;
        }

        &-label {
            color: #6466e9;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
        }
    }

    th {
        width: 100%;
    }

    &__table-item {
        display: flex;
        align-items: center;
        gap: 20px;

        & > img {
            width: 48px;
            height: 48px;
        }
    }
}
