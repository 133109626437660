.topbar {
    // max-width: 1280px;
    background: #fff;
    box-shadow: 0px 1px 0px #DEE2E6;
    width: calc(100% - 16vw);
    position: absolute;
    top: 0;
    z-index: 909 !important;

    .container-header {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        // margin: 0 auto;

        &>div {
            display: flex;
            height: 100%;
            align-items: center;
        }

        .collapse-toggle {
            display: none;
        }
    }

    .dd-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 19vw;
        box-sizing: border-box;
        margin-left: 4rem;
        border-left: 1px solid var(--indigo-100);
        border-right: 1px solid var(--indigo-100);
        height: 7vh;

        .title {
            padding-left: 1.12rem;
            font-size: 0.81rem;
            font-weight: 600;
            color: var(--bluegray-700);
            padding-top: 0.43rem;
        }

        .p-dropdown {
            width: 18vw;
            border: none;
            margin-left: 0.43rem;
            background: none;
            height: 5vh;

            &.p-inputwrapper-focus {
                outline: none;
                box-shadow: none;
            }
        }

        .p-dropdown-label {
            font-size: 1rem;
        }
    }

    .user-area {
        display: flex;
        align-items: center;
        grid-gap: 12px;

        &__notice {
          display: flex;
          position: relative;
          width: 38px;
          height: 38px;
          padding: 7.91px 13.75px 7.9px 13.75px;
          justify-content: center;
          align-items: center;

          border-radius: 1000px;
          background: #F7F7FE;
          color: #9FA9B7;
          cursor: pointer;

          &-active {
            width: 10px;
            height: 10px;

            position: absolute;
            right: 8px;
            top: 8px;

            border-radius: 1000px;
            border: 2px solid #F7F7FE;
            background: #6466E9;
          }
        }

        .p-button {
            height: 2.62rem;
            background: var(--indigo-50);
            color: var(--indigo-500);
            border: none;
        }

        .p-button-icon, .p-button-label {
            font-size: 1rem;
        }

        .profile {
            display: flex;
            align-items: center;
            //margin-left: 1.5rem;
          cursor: pointer;

            .user {
                min-width: 140px;
                margin-left: 0.87rem;

                div {
                    color: var(--bluegray-900);
                }

                span {
                    font-weight: 300;
                    color: var(--bluegray-400);
                    font-size: 0.81rem;
                }
            }
        }
    }

    &.desktop {
        .container-header{
             max-width: 1280px;

             .download-sheets {
                 margin-left: 20px;
                 background: var(--blue-50);
                 color: var(--blue-500);
                 border: 1px solid var(--blue-100);

                 span {
                     font-size: 14px;
                     font-weight: normal;
                 }
             }

             @media screen and (max-width: 1440px) {
                 width: calc(100% - 100px);
             }

            // @media screen and (min-width: 1200px) {
            //     max-width: 880px;
            // }
            //
            // @media screen and (min-width: 1366px) {
            //     max-width: 980px;
            // }
            //
            // @media screen and (min-width: 1600px) {
            //     max-width: 1280px;
            // }
        }
    }

    &.tablet {

        // .container {
        //     margin: 0 20px;
        //     max-width: 860px;
        // }
    }

    &.mobile {
        height: 60px;

        .container {
            margin: 0 1em;
            max-width: unset;
            font-size: 14px;

            .collapse-toggle {
                display: flex;
                width: 36px;
                height: 36px;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
                margin-left: -10px;
            }
        }
    }
}

.select-sheets {
    width: 340px;

    .boxes {
        margin-bottom: 16px;

        .check {
            margin-top: 12px;

            &:first-child {
                margin-top: 0;
            }

            label {
                margin-left: 8px;
                cursor: pointer;
            }
        }
    }

    .p-buttonset {

        .p-button {
            background: var(--indigo-50);
            color: var(--indigo-400);
        }
    }
}

@import './medias.scss';
