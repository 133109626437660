.form {
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 1.25rem;

  background: #fff;
  box-shadow: 1px 1px 20px rgba(59, 130, 246, .1);
  border-radius: 1rem;
  padding: 2rem;

  &__title {
    color: var(--surface-900);

    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.1875rem;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    align-self: stretch;
    flex-wrap: wrap;

    &_row {
      flex-direction: row;
    }
  }

  &__field {
    display: flex;
    justify-content: flex-end;
    align-self: stretch;
    flex-direction: column;
    grid-gap: 0.625rem;
    flex: 1 0 0;

    &_upload {
      margin-top: -1rem;
    }

    .p-fileupload {
      width: 100%;

      &.not-valid {
        border-radius: 0.375rem;
        border: 1px solid #F12F5E;
      }

      .p-button.p-fileupload-choose {
        width: 100%;
        display: flex;
        height: 9.375rem;
        justify-content: center;
        align-items: center;
        grid-gap: 0.4375rem;
        flex: 1 0 0;
        padding: 0;

        border-radius: 0.375rem;
        border: none;
        background: var(--lara-panel-panel-content-bg, #FFF);
        color: #BDBDBD;

        &:hover {
          border: none;
          background: var(--lara-panel-panel-content-bg, #FFF);
          color: #3D75E3;
        }

        .p-button-label{
          flex: none;
          opacity: 0;
          width: 0;
        }

        .p-button-icon-left {
          margin: 0;
        }
      }
    }
  }

  &__label {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__input {
    display: flex;
    padding: 0.65625rem;
    align-self: stretch;
    border-radius: 0.375rem;
    border: 1px solid var(--surface-300);

    &:focus {
      border: 1px solid var(--blue-900);
      box-shadow: none !important;
    }

    &:hover {
      border: AccentColor;
    }

    &_invalid {
      border: 1px solid var(--red-700);
    }

    &_area {
      height: 5.875rem;
    }

    &_item {
      display: flex;
      max-height: 11rem;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      gap: 0.4375rem;
      flex: 1 0 0;
      z-index: 10;
      padding: 0;
      position: relative;

      border: 1px solid #CED4DA;
      background: var(--lara-panel-panel-content-bg, #FFF);

      img, .p-image-preview-container, {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        min-height: 11rem;
        object-fit: cover;
      }
    }
  }

  &__remove-btn {
    position: absolute;
    background: none;
    border: none;
    z-index: 10;
    opacity: 0;
    height: 100%;
    min-width: 100%;

    .pi {
      font-size: 2rem;
    }

    &:hover {
      border-radius: 0.375rem;
      border: 1px solid #CED4DA!important;
      background: linear-gradient(0deg, rgba(84, 111, 165, 0.50) 0%, rgba(84, 111, 165, 0.50) 100%)!important;
      opacity: 1;
    }
  }

  &__button {
    display: flex;
    padding: 0.65625rem 1.09375rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 1.75rem;
    border: 1px solid var(--blue-800);
    background: var(--blue-800);

    color: var(--surface-100);

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.0625rem;

    &:hover {
      background: var(--blue-900)!important;
    }

    &:focus {
      border: 1px solid var(--blue-800);
      box-shadow: none !important;
    }
  }
}