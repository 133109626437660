.circle-to-circle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;
  align-self: stretch;

  &__header {
    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }

  &__content {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-gap: 12px;
    align-self: stretch;
  }

  &__team {
    display: flex;
    height: 81px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;

    border-radius: 10px;
    background: #F7F7FE;

    color: #495057;
    text-align: center;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;

    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &:hover:not(.circle-to-circle__team_disabled) {
      opacity: 0.85;
    }

    &_selected {
      color: #FFF;
      background: #6466E9 !important;
    }

    span {
      color: #B1B2F4;
      text-align: center;
      font-family: Gilroy, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    &_disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}