.media-bank {
    flex: 1 0 auto;

    & .p-tabview-nav {
        li {
            div {
                padding: 17.5px 24px;
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                color: var(--Lara-Tabview-tabviewHeaderTextColor, #6C757D);
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
            }

            &.p-tabview-selected {
                div {
                    color: #6466E9;
                }
            }
        }
    }

    & .p-tabview-panels {
        padding: 24px;
    }

    & .p-tabview {
        border-radius: 10px;
        overflow: hidden;
        background: #FFF;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
    }

    &__clubs {
        border-radius: 10px;
        background: #FFF;
        padding: 24px;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
    }

    &__upload {
        & .p-button-label {
            display: block;
        }
    }

    &__overlay {
        padding: 12px 0;
        border-radius: 6px;
        border: 1px solid var(--Lara-Menu-overlayMenuBorder, rgba(255, 255, 255, 0.00));
        background: #FFF;
        z-index: 20;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);

        min-width: 218px;
        // position: absolute;
        // top: calc(-100% - 3px);
        // left: 85%;
        transition: .3s ease;

        &-item {
            padding: 10.5px 17.5px;
            background: var(--Lara-Menu-overlayMenuBg, #FFF);
            transition: .3s ease;
            color: var(--Lara-Input-Lists-inputListItemTextHoverColor, #495057);
            font-family: "Object Sans";
            font-size: 14px;
            font-weight: 400;
            line-height: 21px; /* 150% */

            &:last-child {
                color: var(--Lara-Button-dangerButtonBg, #EF4444);
            }

            &:hover {
                cursor: pointer;
                background: var(--Lara-Input-Lists-inputListItemHoverBg, #E9ECEF);
            }
        }
    }

    &__images {
        gap: 24px;
        // display: flex;
        // flex-wrap: wrap;
        display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(200px, auto));
        grid-template-columns: repeat(5, minmax(100px, auto));

        &-item {
            position: relative;
            height: 130px;
            border-radius: 6px;
            overflow: hidden;
            max-width: 200px;

            &::before {
                content:'';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(40, 41, 96, 0.70) 0%, rgba(40, 41, 96, 0.70) 100%);
                opacity: 0;
                visibility: hidden;
                transition: .3s ease;
            }
    
            &:hover {
                cursor: pointer;

                .pi-ellipsis-h {
                    opacity: 1;
                    visibility: visible;
                    z-index: 999999;
                }

                &::before{
                    content:'';
                    opacity: 1;
                    visibility: visible;
                    transition: .0s ease;
                }
            }

            img, video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .pi-ellipsis-h {
                position: absolute;
                right: 8px;
                top: 8px;
                border-radius: 100px;
                background: var(--Lara-Surface-surface-0, #FFF);
                padding: 4px;
                transition: .3s ease;
                opacity: 0;
                visibility: hidden;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }
            }

            &.selected {
                &::before{
                    content:'';
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &-label {
            padding: 2px 8px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 0.20);
            backdrop-filter: blur(2px);
            position: absolute;
            left: 7px;
            bottom: 8px;
            color: #FFF;
            font-size: 12px;
            font-weight: 400;
            line-height: 21px; /* 175% */
        }

        &-checkbox {
            position: absolute;
            right: 8px;
            top: 8px;
        }
    }

    &__dropdowns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;

        &-item {

        }
    }

    &__buttons {
        position: fixed;
        right: 36px;
        bottom: 20px;
        display: flex;
        align-items: center;
        gap: 12px;

        & .p-button {
            border-radius: 6px;
            color: var(--Lara-Button-buttonTextColor, #FFF);
            font-family: "Object Sans";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px; /* 121.429% */
        }

        &-cancel {
            border: 1px solid #6466E9;
            background: #6466E9;
        }

        &-delete {
            border: 1px solid var(--Lara-Button-dangerButtonBg, #EF4444);
            background: var(--Lara-Button-dangerButtonBg, #EF4444);

            &:enabled:hover {
                background: var(--Lara-Button-dangerButtonBg, #d92121);
                border: 1px solid var(--Lara-Button-dangerButtonBg, #d92121);
            }
        }
    }

    &__empty {
        color: var(--Lara-Global-textSecondaryColor, #6C757D);
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 34px; /* 242.857% */
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.media-bank__images-tooltip {
    border-radius: 6px;
    background: var(--Lara-Tooltip-tooltipBg, #495057);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
    min-width: 300px;

    &__row {
        display: grid;
        align-items: center;
        gap: 5px;
        grid-template-columns: 110px auto;
        color: var(--Lara-Blue-Gray-bluegray-300, #9FA9B7);
        font-family: "Object Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 21px; /* 150% */
        align-self: start;
        text-align: left;

        span {
            color: var(--Lara-Tooltip-tooltipTextColor, #FFF)
        }
    }
}

.p-overlaypanel {
    &::after, &::before{
        display: none;
    }
}

.media-bank__dialog {
    min-width: 870px;

    & .p-dialog-header {
        color: var(--Lara-Dialog-dialogHeaderTextColor, #343A40);
        font-family: "Object Sans";
        font-size: 17.5px;
        font-weight: 800;
        line-height: 21px; /* 120% */
        padding: 21px;

        & .p-dialog-header-icon {
            width: 28px;
            height: 28px;

            & .p-dialog-header-close-icon {
                font-size: 13px;
            }
        }
    }

    &-buttons {
        margin-top: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 7px;

        & .p-button {
            margin: 0 !important;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px; /* 121.429% */
            padding: 8.5px 17.5px;
        }
    }

    &-support {
        color: var(--Lara-Button-dangerButtonBg, #EF4444);
        border: 1px solid transparent;
        background: var(--Lara-Overlays-overlayContentBg, #FFF);

        &:enabled:hover {
            color: var(--Lara-Button-dangerButtonBg, #ea6e6e);
            border: 1px solid transparent;
            background: var(--Lara-Overlays-overlayContentBg, #FFF);
        }
    }

    &-close {

    }
}