.tab-table {
    border-radius: 10px;
    background: #fff;

    /* overlayMenuShadow */
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

    &__icon {
        color: var(--Lara-Surface-surface-0, #fff);
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 14.41px;
        background: var(--Lara-Blue-blue-500, #3d75e3);
        padding: 7.91px 6.18px 7.9px 6.17px;
        width: 28.81px;
        height: 28.81px;
    }

    &__roles {
        text-wrap: nowrap;
        color: var(--Lara-Blue-blue-500, #3d75e3);
        font-size: 10.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.8px; /* 150.476% */
        border-radius: 6px;
        background: var(--Lara-Blue-blue-50, #f5f9ff);
        padding: 3.5px 5.6px;
    }

    .data-table {
        box-shadow: unset !important;
        .data-table__search {
            display: block !important;
            position: static;
        }
        .p-datatable-thead tr {
            background: #fff;
            padding: 12px 24px;
        }

        .p-datatable-thead > tr > th {
            display: none;
            background: #fff !important;
            border: none;
        }

        .p-inputtext {
            width: 100%;
            padding-left: 34px;
        }

        .data-table__search .p-column-header-content {
            padding: 0;
        }

        .pi-search {
            right: 0 !important;
            left: 12px;
        }
    }

    .p-tabview-panels {
        padding: 0;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: unset;
    }

    .count {
        width: 20% !important
        ;
    }
}
