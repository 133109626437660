.tournamets2-platform {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    &__notfound {
        color: #6c757d;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: calc(50vh - 150px);
    }
}
