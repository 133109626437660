.multifileupload {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;

  .fileupload {
    height: 11.44rem;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  }

  &__label {
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 0.88rem;
    line-height: 1;
    color: var(--lara-blue-gray-bluegray-400);
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: 183px;

    .fileupload__input {
      min-height: 100%;
    }

    & .custom-upload {
      width: 100%;

      & .p-button {
        width: 100%;
        justify-content: center;
        border-radius: 6px;
        border: 1px solid var(--Lara-Button-buttonBorder, #6466E9);
        background: transparent;
        color: var(--Lara-Button-buttonBg, #6466E9);
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        transition: .3s ease;
        margin: 0;

        @media (hover: hover){
          &:hover{
            cursor: pointer;
            opacity: .8;
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}