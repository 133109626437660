.scoreboard{
    width: 70px;
    height: 80px;
    background: #326FD1;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    &::before{
        content: '';
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.08);
        position: absolute;
        top: -10px;
        left: -55px;
    }

    &.active::before{
        z-index: -1;
    }
    .scoreboard__block{
        position: absolute;
        bottom: 0;
        left:0;
        width: 70px;
        height: 39px;
        background: #183462;
        opacity: 0.15;
        border-radius: 6px;
        &.active{
            z-index: -1;
        }
    }
    .p-inplace-display,.scoreboard__number{
        position: absolute;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 21px;
        text-align: center;
        color: #F5F9FF;
        z-index: 99;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .p-inplace{
        overflow: hidden;

        input{
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 21px;
            text-align: center;
            color: #F5F9FF;
            width: 100%;
            background: #326FD1;
        }
    }
    .p-inplace .p-inplace-display:focus{
        box-shadow: unset;
        outline: unset;
        outline-offset:unset;
        width: 100%;
        height: 100%;

    }
    .p-inplace .p-inplace-display:not(.p-disabled):hover{
        background: #326FD1;
        color: #F5F9FF;

    }
}
