.sideblock {
    width: 16vw;
    height: 100vh;
    padding: 1.625rem 0.625rem 0.625rem;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .divider {
        margin: 1.12rem 0;
        height: 1px;
        background: #1B1C4B;
        position: relative;
    }

    &.compact {
        width: 260px;

        .divider {
            margin: 14px 0;
        }

        &.collapsed {
            padding-left: 193px;
        }
    }

    &.collapsed {
        padding-left: 233px;
    }

    .p-skeleton {
        background-color: #222352;
    }
}

.p-menu._in_sideblock {
    width: 340px;
    max-height: 750px;
    overflow-x: auto;
    .emblem.xs {
        width: 20px;
        height: 20px;
        margin-right: 14px;
    }

    .p-menuitem-text {
        font-size: 0.75rem;
        font-weight: 600;
    }
}

@import './medias.scss';
