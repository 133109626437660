.locations-v2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    &__header {
        border-radius: 10px;
        background: #fff;
        padding: 24px;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-select-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }
    }

    .p-buttonset {
        box-shadow: none;
        overflow: unset;
    }

    &__button {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        grid-gap: 0.5rem;
        position: fixed;
        bottom: 1.87rem;
        right: 4.37rem;
        margin-top: 24px;
        margin-left: auto;
        z-index: 99;
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__dialog {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--Lara-Global-textColor, #495057);
            font-feature-settings: "liga" off, "clig" off;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
        }
    }

    .p-dialog {
        max-width: 890px;
        width: 100%;
        // height: 700px;

        &-header {
            display: flex;
            gap: 12px;
            align-items: center;

            &-icons {
                align-self: unset;
            }
        }

        &-content {
            padding: 0 !important;
            // border-radius: 10px !important;

            border-radius: 0 0 6px 6px !important;
        }

        .data-table {
            .p-datatable-table {
                border-radius: 0;
            }
            .p-paginator {
                position: static;
                border-top: 1px solid #dfe7ef;
                padding-bottom: 24px;
            }
        }
    }

    & .p-paginator.p-component.p-paginator-bottom {
        bottom: -72px;
    }
}

.p-sidebar.locations-v2__sidebar {
    width: 516px !important;

    & .locations-v2__sidebar-title {
        color: var(--Lara-Global-textColor, #495057);
        font-feature-settings: "liga" off, "clig" off;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    & .p-sidebar-header {
        position: relative;
        border-bottom: 1px solid #dfe7ef;
        padding: 12px 24px;
        min-height: 49px;
    }

    & .p-sidebar-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 24px;
    }

    & .locations-v2__sidebar-header {
        display: flex;
        align-items: center;
        gap: 8px;
        position: absolute;
        top: 12px;
        left: 24px;
        z-index: 100;

        & .p-button {
            padding: 4.5px 12px;
            color: var(--Lara-Button-buttonTextColor, #fff);
            font-size: 12px;
            font-weight: 400;
            line-height: normal;

            &.locations-v2__sidebar-delete {
                border: 1px solid var(--Lara-Button-dangerButtonBg, #ef4444);
                background: var(--Lara-Button-dangerButtonBg, #ef4444);
            }

            &.locations-v2__sidebar-copy {
                border: 1px solid #6466e9;
                background: transparent;
                color: #6466e9;
            }
        }
    }

    & .locations-v2__sidebar-item {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    & .locations-v2__sidebar-label {
        color: var(--Lara-Global-textColor, #495057);
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    & .p-propdown-label,
    & .p-inputtext {
        color: var(--Lara-Input-Field-inputTextColor, #495057);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        padding: 10.5px;
    }
    .fileupload {
        min-height: unset;
    }
    .fileupload__field {
        max-height: 300px;
    }
}
