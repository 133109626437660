.listInvite {
    border-radius: 10px 10px 0px 0px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
    }

    &__title {
        color: var(--Lara-Global-textColor, #495057);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px; /* 170% */
    }

    &__btn {
        .p-button-label {
            color: var(--Lara-Button-buttonTextColor, #fff);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px; /* 121.429% */
        }
    }

    .data-table .p-datatable-thead th {
        width: 100%;
    }

    .data-table {
        box-shadow: none;
    }

    .p-datatable-table {
        border-radius: 0;
    }
}
