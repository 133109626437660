.layout-desktop {
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    .aside {
        width: 16vw;
        height: 100vh;
        background-color: var(--blue-50);
    }

    .page {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding-top: calc(7vh + 10px);

        .topbar {
            padding: 0 4.62rem;
            box-sizing: border-box;
            background: #fff;
            box-shadow: 0px 1px 0px #DEE2E6;
            margin-bottom: 1.12rem;
            height: 7vh;

            .container {
                height: 7vh;
            }

            @media screen and (max-width: 1440px) {
                padding: 0 3.12rem;
            }
        }

        .container {
            height: 100%;
            border-bottom: none;
            position: relative;
            padding: 0.25rem 4.62rem 0;
            box-sizing: border-box;
            padding-bottom: 48px;
            // margin-top: calc(8vh + 1.12rem) !important;
            //height: calc(100vh - 4.18rem);
            // max-width: 700px;
            //
            // @media screen and (min-width: 1200px) {
            //     max-width: 880px;
            // }
            //
            // @media screen and (min-width: 1366px) {
            //     max-width: 980px;
            // }
            //
            @media screen and (max-width: 1440px) {
                padding-top: 0px;
                width: 1080px;
            }
            //
            // @media screen and (min-width: 1600px) {
            //     max-width: 1280px;
            // }

            .container-grid {
                display: grid;
                grid-template-columns: 6fr 2.8fr;
                grid-gap: 50px;
            }
        }
    }
}
