.input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-gap: 10px;
  width: 100%;

  &__label {
    color: var(--Lara-Global-textColor, #495057);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px; 

    span {
      color: var(--lara-blue-gray-bluegray-400);
      font-family: var(--font-family);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 5px;
    }

    span.grey {
      color: var(--Lara-Blue-Gray-bluegray-300, #9FA9B7);
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    } 
  }

  &__description {
    display: flex;
    padding: 16px 10.5px;
    flex-direction: column;
    grid-gap: 2px;
    align-self: stretch;
    margin-top: -10px;

    border-radius: 0 0 6px 6px;
    background: var(--lara-root-surface-hover);

    color: var(--lara-global-textsecondarycolor);
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }

  .input {
    display: flex;
    padding: 10.5px;
    align-items: flex-start;
    grid-gap: 14px;
    align-self: stretch;
    width: 100%;

    border-radius: 6px;
    border: 1px solid var(--Lara-Input-Field-inputBorder, #CED4DA) !important;
    background: var(--lara-input-field-inputbg);

    // color: var(--Lara-Global-textColor, #495057) !important;
    color: var(--Lara-Action-Icons-actionIconHoverColor, #343A40);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px !important;

    input {
      border-radius: 0;
    }

    &_invalid {
      border: 1px solid var(--lara-input-field-inputerrorbordercolor);
    }
  }

  .pi-spin {
    margin-top: -.5rem;
  }
}

.p-inputtext:focus {
  box-shadow: none;
}