.color-dd-item {
    width: 2rem;
    aspect-ratio: 2/1.3;
    border-radius: .2rem;
    border: 1px solid var(--blue-50);
}

.stream-panel {

    .stream-form {
        padding: 1rem 0;

        .switch-control {
            margin-top: .75rem;
            display: flex;
            align-items: center;

            label {
                margin-left: .75rem;
            }
        }

        .stream-form-section {
            margin-top: .75rem;
            padding-top: .75rem;
            border-top: 1px solid var(--blue-100);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .stream-form-section-label {
                font-size: .8rem;
                font-weight: 600;
                width: 100%;
            }

            .stream-form-section_half {
                width: 48%;
                padding: .75rem 0;
            }

            .p-inputgroup {
                margin-top: .75rem;

                .p-inputgroup-addon {
                    font-size: .75rem;
                    padding: .3rem;
                    min-width: unset;
                }

                .p-inputtext {
                    padding: .3rem .15rem;
                    display: flex;
                    justify-content: center;
                }

                .p-dropdown-trigger {
                    display: none;
                }
            }

            .widgets-trigers {
                display: flex;
                gap: .5rem;
                flex-wrap: wrap;

                .p-button-sm {
                    padding: .5rem 1rem;
                    color: var(--blue-500);
                    background: var(--blue-50);
                    border: 1px solid var(--blue-100);

                    .p-button-label {
                        font-weight: normal;
                        font-size: .8rem;
                    }
                }
            }
        }
    }
}
