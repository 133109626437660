.roles-access {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100% - 17rem);
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      height: 3px;
      margin-left: 30px;
    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: var(--Lara-Root-surface-border, #DFE7EF);
      border-radius: 10px;
    }

    &__checkbox {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .p-accordion-tab {
        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
        margin: 0;
    }

    .p-accordion-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .p-accordion-header-link {
        color: var(--Lara-Blue-Gray-bluegray-400, #818ea1) !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 14px; /* 100% */
    }

    .p-accordion-tab-active {
        .p-accordion-header-link {
            color: var(--Lara-Accordion-accordionHeaderTextHoverColor, #343a40) !important;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .p-accordion-header-text {
        width: 100%;
    }

    &__btn {
        border-radius: 6px;
        background: #6466e9;
        padding: 3px 10px;
        color: var(--Lara-Button-successButtonTextColor, #fff);
        font-size: 10.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.8px; /* 150.476% */

        &.disabled {
            background: var(--Lara-Blue-Gray-bluegray-200, #bcc3cd);
        }
    }
}
