.card {
  display: flex;
  width: 100%;
  padding: 2rem 2.25rem;
  flex-direction: column;
  grid-gap: 1.375rem;
  position: relative;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
    
  &_info {
    padding: 1rem 1.5rem;
    grid-gap: 0.625rem;
  }

  .p-card-header {
    color: var(--lara-global-textcolor);
    font-family: var(--font-family);
    font-size: 1.09375rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.64063rem;
  }
}