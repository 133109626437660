.tournamets2-settings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    &__card {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__label {
        color: #495057;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; 
    }

    &__inputnum {
        input {
            max-width: 62px;
            padding: 10px;
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }

        .p-inputtext::placeholder {
            color: #6c757d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &__title {
        color: #495057;
        font-size: 17.5px;
        font-style: normal;
        font-weight: 800;
        line-height: 26.25px;
    }

    &__switch {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__radio {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__scheme {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px 0;
    }

    &__dates {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      margin-left: 54px;

      .p-datepicker-trigger {
        right: 0;
        border: none;
        background: none !important;
        color: #6c757d !important;
      }
    }
}
