.group-to-circle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  align-self: stretch;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //grid-gap: 16px;
    align-self: stretch;
  }

  &__container {
    display: flex;
    padding: 32px 32px 32px 12px;
    align-items: center;
    grid-gap: 8px;
    align-self: stretch;

    border-radius: 10px;
    border: 1px solid #EFF3F8;
    background: #F7F7FE;
  }

  &__group-label {
    transform: rotate(-90deg);
    color: #9EA0F6;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    white-space: nowrap;
  }

  &__team {
    background: #FFF;
  }
}