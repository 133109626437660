.publications {
    width: 76.65vw;
    padding-bottom: 24px;

    .content {
        display: flex;
        width: 76.65vw;

        .list {
            height: calc(100vh - 150px);
            width: 75%;
            display: flex;
            margin: 0;
            padding: 20px;
            padding-top: 0;
            margin-left: -1.25rem;
            box-sizing: border-box;
            flex-direction: column;

            .loader {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
            }

            .card {
                border-radius: 12px;
                background: #fff;
                padding: 1.25rem 1.25rem 1.06rem;
                box-shadow: 2px 2px 30px #dadafc50;
                display: flex;
                box-sizing: border-box;
                position: relative;
                align-items: flex-end;
                transition: all 0.4s ease-in-out;
                margin-bottom: 1.56rem;
                margin-right: 0.93rem;

                &:first-child {
                    margin-top: 1.25rem;
                }

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                    box-shadow: none;
                }

                .tags {
                    position: absolute;
                    display: flex;
                    top: -0.75rem;
                    right: 2.5rem;
                    left: 1.25rem;

                    .p-tag {
                        margin-right: 0.375rem;
                        background: var(--indigo-100);
                        color: var(--indigo-600);

                        &.date {
                            background: var(--bluegray-50);
                            color: var(--bluegray-500);
                        }

                        &.imported {
                            background: var(--blue-100);
                            color: var(--blue-600);
                            position: absolute;
                            right: 0;
                        }
                    }

                    .menu-button {
                        position: absolute;
                        right: -1.625rem;
                        top: 1.875rem;
                        height: 0.625rem;
                        width: 0.625rem;
                        cursor: pointer;
                        background: none;
                        border: none;
                        color: rgba(59, 130, 246, 0.5);

                        &:hover {
                            color: #3b82f6;
                        }
                    }
                }

                .mean {
                    width: 50%;
                    color: var(--bluegray-800);

                    .title {
                        margin-bottom: 0.75rem;
                    }

                    .intro {
                        font-weight: 300;
                        font-size: 0.75rem;
                        max-height: 2.81rem;
                        overflow: hidden;
                    }
                }

                .col {
                    width: 25%;
                    color: var(--bluegray-800);
                    box-sizing: border-box;
                    padding-left: 0.875rem;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-right: 1.25rem;

                    &:last-child {
                        align-items: flex-end;
                    }

                    .label {
                        font-size: 0.75rem;
                    }

                    .value {
                        font-size: 0.75rem;
                        font-weight: 600;
                        color: var(--bluegray-600);
                    }

                    .p-tag {
                        font-weight: normal;
                        margin-top: 0.5rem;

                        &.p-tag-info {
                            background: var(--blue-50);
                            color: var(--blue-400);
                        }

                        &.p-tag-warning {
                            background: var(--orange-50);
                            color: var(--orange-400);
                        }

                        &.muted {
                            background: var(--bluegray-50);
                            color: var(--bluegray-500);
                            margin-top: 0;
                        }
                    }

                    .empty {
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        flex-direction: column;
                        width: 120px;
                        aspect-ratio: 16 / 9;
                        background: var(--bluegray-50);
                        border-radius: 0.5rem;

                        .pi {
                            font-size: 1.125rem;
                        }

                        div {
                            font-size: 0.6875rem;
                            margin-top: 0.375rem;
                        }
                    }
                }
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 24px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;

        &-item {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: var(--Lara-Blue-blue-50, #f5f9ff);
        width: 136px;
        height: 92px;
        padding: 12px 0;

        .pi {
            color: #bcc3cd;
        }
    }

    &__date {
        display: flex;
        flex-direction: column;
        gap: 2px;
        color: #9fa9b7;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;

        span {
            color: #495057;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-pack: center;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            box-orient: vertical;
            height: -moz-fit-content;
            height: fit-content;
        }
    }

    &__vk {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 36px;
        margin-top: 118px;

        &-title {
            color: var(--Lara-Global-textColor, #495057);
            font-family: Inter;
            font-size: 17.5px;
            font-style: normal;
            font-weight: 800;
            line-height: 26.25px; /* 150% */
            margin-bottom: 24px;
        }
    }

    .data-table {
        .body-item__default {
            -webkit-line-clamp: 2;
        }

        .p-datatable-tbody tr {
            min-height: 116px;
        }

        .p-inputtext {
            padding: 10.5px 7px;
            color: var(--Lara-Input-Field-inputPlaceholderTextColor, #6c757d);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
        }

        .p-sortable-column-icon {
            color: #6466e9 !important;
        }
    }

    .p-buttonset {
        box-shadow: none;
        overflow: unset;
        display: flex;
    }

    .p-button {
        border-color: #6466e9;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        // width: 100%;
        text-align: center;
        justify-content: center;
        padding: 10.5px 17.5px;
    }

    .p-selectbutton {
        .p-button {
            color: #6466e9;

            &.p-highlight {
                background: #6466e9;
                color: #fff;
            }
        }

        .p-button-label {
            font-family: "Object Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
        }
    }

    .p-dialog-content {
        display: flex;
        padding: 0 !important;
        overflow-y: auto !important;
        min-height: 300px;
    }

    .p-dropdown {
        border-color: #6466e9;
        height: 38px;

        &-label {
            line-height: 14px;
            color: #6466e9;
        }

        .pi {
            color: #6466e9;
        }
    }
}

.wrapper-bar {
    position: absolute;
    top: -20px;
    display: flex;
    width: 50%;
    box-sizing: border-box;
    padding: 0 0.75rem;
    z-index: 20;
    left: -5px;

    .p-button {
        width: auto;
    }
}

@import "./medias.scss";
