.dream-lineup {
  &__tactic-img {
    background-image: url("../../../../../../../assets/img/football.svg");
    width: 100%;
    height: 325px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 64px;
    // .tactic__img_block{
    //     width: 40px;
    //     height: 40px;
    //     background: #F7F8F9;
    //     box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    //     border-radius: 6px;
    //     padding: 11px;
    //     box-sizing: border-box;
    //     transition: 0.2s;
    //
    //     .pi-clock{
    //         color:#9FA9B7
    //     }
    //     .pi-user{
    //         color:#FFFFFF
    //     }
    // }
  }

  &__tactic-img.team {
    display: flex;
    flex-direction: column;
    // margin: 0 1rem;
    // width: calc(100% - 2rem);
    background-size: contain;
    position: relative;

    .forws {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: center;
      gap: 110px;
    }
    .middles {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 80px;
      margin-top: 50px;
    }
    .defs {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 120px;
      margin-top: 45px;
    }
    .keeper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 50px;
    }


  }
}