@media screen and (max-width: 768px) {
    .tournaments {
        width: 100%;

        .content.within-list {
            width: 100%;

            .tournament-settings {
                margin: 0;
                padding: 0 12px;

                .title {
                    margin-top: 14px;

                    .text {
                        font-size: 14px;
                        font-weight: normal;
                        margin-bottom: 6px;
                        text-align: center;
                    }

                    input {
                        text-align: center;
                    }
                }

                .fields-grid {
                    display: flex;
                    flex-direction: column;
                }
            }

            .tournament-teams {
                margin: 0;
                padding: 0 12px;

                .title {
                    margin-top: 14px;

                    .text {
                        font-size: 14px;
                        width: 100%;
                        font-weight: normal;
                        margin-bottom: 6px;
                        text-align: center;
                    }

                    input {
                        text-align: center;
                    }
                }

                .teams-content {
                    width: 100%;
                    padding-top: 10px;
                    gap: 14px;
                    justify-content: center;

                    .team {
                        width: 180px;
                        padding: 12px 7px;
                        margin: 0;
                        height: unset;

                        .text-area {
                            margin-left: 10px;

                            .text {
                                font-size: 12px;
                                font-weight: normal;
                            }
                        }

                        .emblem.md {
                            width: 38px;
                            height: 38px;
                        }
                    }
                }
            }
        }
    }
}
