.circle-to-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  align-self: stretch;

  &__header {
    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    align-self: stretch;
  }

  &__label {
    display: flex;
    align-items: center;
    grid-gap: 8px;

    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    cursor: pointer;

    .pi {
      padding-top: 2px;
      font-size: 12px;
      color: #6C757D;
    }
  }
}