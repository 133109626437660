.side-profile {
    width: 32% !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;

    &__overlay {
        z-index: 901 !important;
    }

    .p-accordion {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-tab {
            border-bottom: 1px solid #e9ecef !important;

            padding-bottom: 20px !important;
        }

        &-header-link {
            flex-direction: row-reverse;
            justify-content: space-between;
            background: none !important;
            border: none !important;
            padding: 0 !important;
            border-radius: 0;

            &:focus {
                box-shadow: none !important;
            }
        }

        &-content {
            border: 0;
            padding: 0;
            margin: 0;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    .p-sidebar {
        &-content {
            padding: 20px 24px 0px 24px !important;
        }

        &-header {
            padding: 12px 24px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__button-delete {
        border-color: #ef4444 !important;

        .p-button-label {
            color: #ef4444 !important;
        }
    }

    &__items {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .fileupload__label {
        display: flex;
        flex-direction: column;

        span {
            margin: 0;
        }
    }

    .fileupload__field {
        justify-content: unset;

        .p-fileupload {
            height: 177px;
        }
    }

    .match {
        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #495057;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &__date {
            color: #9fa9b7;
        }

        &__team {
            width: 24px;
            height: 24px;
            object-fit: contain;
        }

        &__score {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .location-profile__autocomplete {
        &.wClub {
            input {
                padding-left: 0;
                border-left: 0;
                box-shadow: unset !important;

                &:hover {
                    border-color: #ced4da !important;
                }
            }
        }
    }

    .p-inputgroup {
        &-addon {
            background: transparent;
            padding-right: 6px;
            & > img {
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
        }
    }

    &__calendar {
      input {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
      }
      button {
        background: none;
        border: none;
        right: 0;
        height: 100%;
      }
    }
}
