.clubs-profile__content {

    &.logos {
        grid-template-columns: repeat(3, 1fr);

        & .fileupload, .fileupload__input {
            min-height: 160px;
        }
    }

    &.themes {
        grid-template-columns: repeat(3, 1fr);

        img {
            width: 100%;
            transition: .3s ease;
            border-radius: 6px;
            border: 1px solid transparent;

            @media (hover: hover){
                &:hover{
                    cursor: pointer;
                }
            }

            &.active {
                border: 1px solid #6466E9;
                box-shadow: 0px 0px 0px 4px #BFC9FE;
            }
        }
    }
}