.auto-playoff {
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 24px;

  &__select {
    display: flex;
    padding: 0 24px;
    align-items: flex-start;
    grid-gap: 10px;
    align-self: stretch;
    min-width: 330px;
    flex-direction: column;
    flex: 1 0 0;

    .p-dropdown {
      width: 100%;
    }
  }

  &__label {
    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}