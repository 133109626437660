.score-control {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin-top: 1rem;

  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);

  &__button {
    display: flex;
    width: 100% !important;
    height: 38px;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 6px;
    border: none !important;
    background: #F7F7FE !important;

    span {
      color: #6466E9;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
    }

    &_orange {
      background: #FFF8F3 !important;

      span {
        color: #FF9F42;
      }
    }
  }

  &__container {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  &__actions {
    display: flex;
    height: 80px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
  }

  &__score {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    overflow: hidden;
  }

  &__cell {
    display: flex;
    position: relative;
    width: 70px;
    height: 80px;
    padding: 11px 19.17px 11px 19.16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 6px;
    background: #6466E9;

    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      background: rgba(0, 0, 0, .06);
    }

    &::after {
      content: '';
      background: rgba(255, 255, 255, .07);
      width: 120px;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      bottom: -20px;
      left: -80px;
      z-index: 2;
    }
  }

    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    //height: 120px;
    //width: 100%;
    //margin-top: 14px;
    //padding: 0 20px;
    //flex-wrap: wrap;

    .qty-control {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;
    }

    .teams-triggers {
        width: 100%;
        padding-top: 14px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .score-main {
        width: 152px;
        display: flex;
        justify-content: space-between;
        position: relative;

        &::before {
            content: '';
            background: #fff;
            width: 180px;
            height: 70px;
            position: absolute;
            left: -14px;
            top: -4px;
            border-radius: 6px;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        }

        .match-main_score_cell {
            width: 70px;
            height: 80px;
            border-radius: 6px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 48px;
            font-weight: 600;
            overflow: hidden;
            margin-top: -28px;
            background: var(--blue-500);

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 40px;
                background: rgba(0, 0, 0, .06);
            }

            &::after {
                content: '';
                background: rgba(255, 255, 255, .07);
                width: 120px;
                height: 120px;
                border-radius: 50%;
                position: absolute;
                bottom: -20px;
                left: -80px;
                z-index: 2;
            }
        }
    }
}
