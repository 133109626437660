.team-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__empty {
        text-align: center;
    }

    &__card {
        padding: 24px;
        box-sizing: border-box;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        width: 100%;

        &-title {
            color: #495057;
            font-size: 17.5px;
            font-style: normal;
            font-weight: 800;
            line-height: 26.25px;
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__container {
        display: flex;
        gap: 24px;
    }

    &__input {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-label {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        margin-top: 24px;
    }

    &__search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        position: relative;
        margin: 16px 0;
        padding: 0 24px;
        box-sizing: border-box;

        input {
            width: 100%;
        }

        .pi-search {
            position: absolute;
            right: 38px;
        }
    }

    &__players {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 24px 0;
        box-sizing: border-box;

        &-title {
            padding: 0 24px;
            box-sizing: border-box;
            color: #495057;
            font-size: 17.5px;
            font-style: normal;
            font-weight: 800;
            line-height: 26.25px;
        }

        &-container {
            max-height: 320px;
            height: 100%;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 5px;
                height: 3px;
                margin-left: 30px;
            }
            &::-webkit-scrollbar-thumb {
                height: 50px;
                background-color: #dfe7ef;
                border-radius: 10px;
            }
        }
    }

    &__tip {
        background: #f7f8f9;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 16px;

        .pi {
            color: #818ea1;
        }

        &-text {
            color: #818ea1;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
        }
    }

    &__player {
        // display: flex;
        align-items: center;
        width: 100%;
        // justify-content: space-between;
        // gap: 24px;
        padding: 12px 24px;
        box-sizing: border-box;
        border-bottom: 1px solid #dfe7ef;
        transition: 0.3s ease;
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 1fr;

        @media (hover: hover) {
            &:hover {
                background: #f7f7fe;
                cursor: pointer;
            }
        }

        &-position {
            color: #6466e9;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 15.8px;
            padding: 11.5px 14px;
            box-sizing: border-box;
        }

        &-name {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 206px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-place {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &-main {
            color: #1ea97c;
            font-size: 10.5px;
            font-style: normal;
            font-weight: 600;
            line-height: 15.8px;
            border-radius: 6px;
            background: #effff5;
            padding: 3.5px 5.6px;
            box-sizing: border-box;
            opacity: 0;
            visibility: hidden;
            text-align: center;
            width: fit-content;

            &.visible {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}



