.calendar {

    .top {
        display: flex;
        align-items: flex-end;

        .action {
            padding-left: 16px;

            .p-button {
                width: 240px;
                // background-color: var(--blue-500);
                // border-color: var(--blue-600);
                display: flex;
                justify-content: center;

                .p-button-label {
                    flex: none;
                }
            }
        }
    }

    .model {
        display: flex;
        height: calc(100vh - 310px);
        box-sizing: border-box;
        padding: 16px 4px 0px;
        align-items: stretch;

        .calendar-card {
            display: flex;
            flex-direction: column;
            align-items: center;

            .p-inline-message.p-inline-message-warn {
                margin-top: 1.5rem;
                width: 90%;
            }

            .buttonset {
                width: 90%;
                height: 2rem;
                margin: 0.5rem 0 1.5rem 0;
                border-radius: 0 0 5px 5px;
                display: flex;
                justify-content: center;

                .p-button.p-button-warning {
                    border-radius: 5px 0 0 5px;
                    width: 50%;
                }

                .p-button.p-button-secondary {
                    border-radius: 0 5px 5px 0;
                    width: 50%;
                }
            }


        }

        .playoffView {
            padding: 0;
            flex-direction: column;
            .plf-dd-wrap {
                top: 0;
                margin-bottom: 20px;
            }
        }

        .calendar-card, .grid-card {
            width: 478px;
            //height: calc(100vh - 218px);
            box-sizing: border-box;
            padding: 30px 0 20px;
            margin-right: 16px;
            background: #fff;
            box-shadow: 1px 1px 15px rgb(99 102 241 / 7%);
            border-radius: 12px;
            position: relative;

            .p-tag.group-title {
                background: var(--blue-500);
                color: var(--blue-50);
                border-color: var(--blue-500);
                position: absolute;
                top: -8px;
                left: 12px;
            }

            &.withoutMds {
                display: flex;
                flex-direction: column;
                //align-items: center;
                //justify-content: space-between;
                h5 {
                    align-self: flex-start;
                }
                .actions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 200px;
                    text-align: center;
                    width: 100%;
                    div {
                        margin-bottom: 20px;
                    }
                }
                button{
                    background: rgba(59, 130, 246, 0.04);
                    border-radius: 6px;
                    color: #64748B;
                    outline: none;
                    border: none;
                }
                .type_auto {
                    color: #3B82F6;
                }
            }

            h5 {
                margin-top: 0;
            }

            .p-inputgroup .p-button {
                background-color: #E9ECEF;
                border-color: #CED4DA;
                color: #6C757D;
            }

            .matchdays-scroll {

                &>div:first-child {
                    padding-bottom: 106px;
                }
            }

            .actions {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                margin-top: 12px;
                text-align: center;
                width: 100%;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;

                &:not(.regular) {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    z-index: 2;
                    background: #fff;
                    padding: 12px 14px 0;
                    box-shadow: -1px 0 15px rgba(0, 0, 0, 0.07);

                    &.cta {
                        position: relative;
                        box-shadow: none;
                        padding: 12px 22px 0;

                        &>div:first-child {
                            margin-bottom: 40px;
                            font-weight: 300;
                            color: var(--bluegray-800);
                        }
                    }
                }

                &.regular {
                    padding: 0 40px;
                }

                div {
                    margin-bottom: 10px;
                    width: 100%;
                }
                button {
                    width: 100%;
                    display: block;
                    white-space: nowrap;
                    font-size: 14px;
                    line-height: 17px;
                    border-radius: 6px;
                    outline: none;
                    border: none;

                    &.p-button-primary {
                        background: var(--indigo-50);
                        color: var(--indigo-500);
                        border-color: var(--indigo-100);
                    }
                }
                .options {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    button {
                        width: 49%;
                    }
                    .cancel {
                        background: rgba(245, 158, 11, 0.16);
                        color: #F59E0B;
                        &:hover {
                            background: rgba(245, 158, 11, 0.26);
                        }
                    }
                    .refresh {
                        background: rgba(100, 116, 139, 0.16);
                        color: #64748B;
                        &:hover {
                            background: rgba(100, 116, 139, 0.26);
                        }
                    }
                }
            }

            .action {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 64px;
                background: #fff;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                padding: 12px;

                .p-button {
                    width: 100%;
                    justify-content: center;

                    &.p-button-primary {
                        background: var(--indigo-50);
                        color: var(--indigo-500);
                        border-color: var(--indigo-100);
                    }

                    .p-button-label {
                        flex: none;
                    }
                }
            }

            .matchday-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }

                .p-inputgroup {

                    .p-inputtext {
                        min-width: 3rem;
                        max-width: 3rem;
                        font-size: .8rem;
                        padding: .5rem;
                        text-align: center;
                        &.isGridMD {
                            max-width: 10rem;
                        }
                    }

                    .p-button {
                        font-size: .8rem;
                        padding: .5rem;

                        &:not(.p-disabled) {
                            background: var(--green-50);
                            color: var(--green-400);
                        }

                        &.btn-edit {
                            background: var(--bluegray-50);
                            color: var(--bluegray-400);
                        }

                        .pi {
                            font-size: .8rem;
                        }
                    }
                }

                .matchday-name {
                    margin-bottom: 10px;
                    position: relative;

                    // .p-inputtext {
                    //     text-align: center;
                    //     padding: 0.5rem;
                    //     font-size: 13px;
                    //     width: 120px;
                    // }
                    div {
                        position: absolute;
                        right: -50px;
                        top: -5px;
                    }
                }

                a {

                    &:nth-child(even) {
                        background: var(--blue-50);
                    }
                }

                .matchday-match {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 44px;
                    position: relative;
                    font-size: 14px;
                    color: var(--bluegray-800);

                    &.group-manual {

                        &:nth-child(even) {
                            background: var(--blue-50);
                        }

                        & > div {
                            width: calc((100% - 64px)/2) !important;

                            &:first-child {
                                padding-left: 8px;

                                .p-dropdown {
                                    flex-direction: row-reverse;

                                    .p-dropdown-clear-icon {
                                        right: unset;
                                        left: 25px;
                                    }

                                    .p-dropdown-label {
                                        justify-content: flex-end;
                                    }
                                }
                            }

                            &:nth-child(3) {
                                padding-right: 8px;
                            }

                            .p-dropdown {
                                max-width: unset;
                                height: 44px;
                                border: none;
                                background: transparent;

                                &.p-inputwrapper-focus {
                                    outline: none;
                                    box-shadow: none;
                                }

                                .p-dropdown-label {
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }

                        a {
                            background: transparent !important;
                            width: 64px !important;
                        }
                    }

                    & > div {
                        width: calc((100% - 64px)/2);
                        &:first-child {
                            text-align: right;
                        }
                        &:nth-child(2) {
                            width: 64px;
                            text-align: center;
                        }
                    }

                    .p-dropdown{
                        width: 100%;
                        max-width: 174px;
                        height: 29px;
                        font-size: 12px;
                        line-height: 21px;
                        color: #6C757D;

                        .p-dropdown-label, .p-inputtext{
                            padding: 3px 6px;
                        }

                        .p-dropdown-clear-icon {
                            font-size: 12px;
                            margin-top: -0.4rem;
                            right: 25px;
                            //display: none;
                        }

                        .p-dropdown-trigger {
                            width: 1.5rem;
                            .p-dropdown-trigger-icon {
                                font-size: 12px;
                            }
                        }

                        .p-inputtext {
                            font-size: 12px;
                            text-align: left;
                            overflow: hidden;

                            .team-item {
                                overflow: hidden;
                            }
                        }
                    }

                    .matchday-match-groupName {
                        position: absolute;
                        width: 58px;
                        left: calc((100% - 58px)/2);
                        top: -8px;
                        font-size: 8px;
                        background: var(--blue-50);
                        color: var(--blue-500);
                        border: 1px solid var(--blue-100);
                        font-size: 9px;
                    }

                    &.group-manual {
                        .matchday-match-groupName {
                            top: -5px;
                        }
                        & > div {
                            text-align: center;
                        }
                    }
                    &.match-manual {
                        & > div {
                            text-align: center;
                        }
                    }
                }
            }
        }
        .grid-card {
            padding: 0 5px;
            margin-right: 0px;
            margin-bottom: 10px;
            .gridplayoff {
                .actions {
                    margin-top: 0px;
                    .options {
                        button {
                            width: 49%;
                            font-size: 12px;
                            line-height: 15px;
                            padding-top: 8px;
                            padding-bottom: 8px;
                        }
                    }
                }
                .matchdays-scroll {

                    &>div:first-child {
                        padding-bottom: 0px;
                    }
                }
            }
        }
        &.customStructure {
            padding: 0;
            //flex-direction: row;
            .customStagesList {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-right: 14px;

                .stageView {
                    position: relative;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    margin-bottom: 14px;

                    padding: 5px;

                    width: 240px;
                    background: #FFFFFF;
                    box-shadow: 0 4px 18px rgba(59, 130, 246, 0.07);
                    border-radius: 15px;

                    transition: opacity .3s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        box-shadow: 0 4px 8px rgba(59, 130, 246, 0.02);
                        opacity: .75;
                    }

                    &.selected {
                        background: var(--indigo-50);
                        border: 1px solid var(--indigo-100);
                        cursor: default;

                        &:hover {
                            cursor: default;
                        }
                    }

                    .descr {
                        display: flex;
                        flex-direction: column;
                        margin-top: 10px;
                        padding: 0 0.87rem;
                        text-align: center;
                        padding-bottom: 20px;
                        height: 60px;

                        .tag {
                            position: absolute;
                            right: 0;
                            top: -10px;
                            padding: 3.5px 5.6px;
                            width: auto;
                            height: 23px;

                            background: #F5F9FF;
                            border-radius: 6px;

                            font-family: 'Inter', sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 0.65rem;
                            line-height: 1rem;

                            color: #3B82F6;
                        }

                        .title {
                            font-family: 'Inter', sans-serif;
                            font-style: normal;
                            font-size: 1rem;
                            line-height: 17px;
                            letter-spacing: .2px;
                            color: var(--bluegray-600);
                            margin-bottom: 3px;
                        }
                    }

                    .icon {
                        width: 40px;
                        height: 40px;
                        margin: 30px 17px 0px 19px;
                    }
                }
            }
        }
    }
}

@import './medias.scss'
