.clubs-members {
    &__avatar {
        background: var(--Lara-Blue-blue-500, #3d75e3);
        width: 32px;
        height: 32px;
        color: var(--Lara-Surface-surface-0, #fff);
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__status {
        border-radius: 6px;
        background: var(--Lara-Green-green-50, #F7F8F9);
        padding: 3.5px 5.6px;
        color: var(--Lara-Blue-Gray-bluegray-400, #818ea1);
        font-family: Inter;
        font-size: 10.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.8px; /* 150.476% */

        &.valid {
            background: var(--Lara-Green-green-50, #effff5);
            color: #0ba186;
        }

        &.notvalid {
            background: var(--Lara-Red-red-50, #fff5f5);
            color: #EF4444;
        }
    }
}
