.group-structure {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__card {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &.teams {
            padding: 0;
        }

        .p-buttonset {
          display: flex;
          border-radius: 0;
          box-shadow: none;
          width: 100%;

          .p-button {
            flex: 1 0 0;
          }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        &.teams {
            padding: 24px 24px 0;
        }
    }

    &__hint {
        background: #f7f8f9;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #818ea1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
    }

    &__title {
        color: #495057;
        font-size: 17.5px;
        font-style: normal;
        font-weight: 800;
        line-height: 26.25px;
    }

    &__input {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: column;

        &-label {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }

        .p-inputtext {
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;

            &::placeholder {
                color: #6c757d;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
            }
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__chips {
        padding: 0 24px 24px;
        box-sizing: border-box;
    }

    .p-autocomplete {
        width: 100%;
    }
    .p-inputtext {
        width: 100%;
    }

    .p-chips {
        width: 100%;
        border-radius: 0;

        .p-inputtext {
            height: 46px;
        }
    }

    &__score {
        &-item {
            display: flex;
            align-items: center;
            
            & .p-inputnumber {
                display: inline-block;
                border-radius: 0 6px 6px 0;
                width: 75px;
                height: 100%;
                height: 36px;
            }

            & .p-inputnumber-input {
                height: 100%;
                border-left: transparent;
            }
        }

        &-name {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            padding: 10px 10px 10px 20px;
            box-sizing: border-box;
            border-radius: 6px 0 0 6px;
            border: 1px solid #ced4da;
            background: #f7f8f9;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            width: 100%;
        }
    }

    &__btn {
        position: fixed;
        bottom: 1.87rem;
        right: 4.37rem;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 0 24px 24px;
        box-sizing: border-box;
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: stretch;

        .p-autocomplete-multiple-container {
            gap: 10px;

            .p-autocomplete-token {
                margin: 0;
            }
        }

        .p-autocomplete-panel {
            top: 100% !important;
            max-height: 156.5px !important;
        }

        &-change {
            transition: 0.3s;
            @media (hover: hover) {
                &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                }
            }
        }

        &-text {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #495057;
            max-width: 126px;
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            border-radius: 6px 0 0 6px;
            border: 1px solid #ced4da;
            background: #f7f8f9;
            padding: 14px 10px 14px 24px;

            &.first {
                transition: 0.3s;
                @media (hover: hover) {
                    &:hover {
                        opacity: 0.8;
                        cursor: pointer;
                    }
                }
            }
        }

        .p-button {
            border-radius: 0 6px 6px 0;
            background: #f7f8f9;
            border-color: #ced4da;

            .p-button-icon {
                color: #6c757d;
            }

            @media (hover: hover) {
                &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                    background: #f7f8f9;
                    border-color: #ced4da;
                }
            }
        }

        .p-inputtext {
            border-radius: 0;
        }
    }
}

// .group-structure__item-change {
//     padding: 0;

//     .p-overlaypanel-content {
//         padding: 0;
//     }

//     span {
//         padding: 12px;
//         box-sizing: border-box;
//     }
// }

.p-overlaypanel-content {
    padding: 0 !important;

    .group-structure__overlay {
        max-height: 355px;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 5px;
            height: 3px;
            margin-left: 30px;
        }

        &::-webkit-scrollbar-thumb {
            height: 50px;
            background-color: var(--Lara-Root-surface-border, #dfe7ef);
            border-radius: 10px;
        }
    }

    .cancel-wrap {
        padding: 0 24px 10px;

        .p-button-sm {
            width: 100%;
            justify-content: center;

            .p-button-label {
                flex: unset;
            }
        }
    }

    .p-inputnumber-input {
        border-radius: 0 6px 6px 0;
        width: 75px;
    }
}
