.location-profile {
    &__stats {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(3, 1fr) !important;
        align-self: stretch;
    }

    &__stat {
        display: flex;
        padding: 24px;
        flex-direction: column;
        grid-gap: 8px;

        border-radius: 10px;
        background: #f8f8fe;

        color: #818ea1;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &_empty {
            span {
                color: #bcc3cd !important;
            }
        }

        span {
            color: #6366f1;
            font-size: 35px;
            font-weight: 600;
        }
    }

    &__autocomplete {
        width: 100%;

        input {
            width: 100%;
        }
    }

    .p-dropdown {
        justify-content: space-between;
    }
}
