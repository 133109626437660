.item {
  margin-top: 79px;

  &__header {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    height: 63px;
    width: 100%;
    padding: 12px;
    align-items: center;
    gap: 12px;

    background: #FFF;
    box-shadow: 1px 1px 22px 0px rgba(0, 0, 0, 0.06);
  }

  &__back {
    display: flex !important;
    width: 38px;
    height: 38px;
    padding: 7.91px 13.75px 7.9px 13.75px;
    justify-content: center;
    align-items: center;

    border-radius: 1000px;
    background: var(--Lara-Indigo-indigo-50, #F7F7FE);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;

    color: #465161;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.5px;
    
    &-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-date {
      color: #9FA9B7;
      line-height: 17px;
    }
  }

  &__emptyblock {
    display: flex;
    height: 63px;
    width: 38px;
    align-items: center;
    gap: 12px;
  }

  &__main {
    display: flex;
    position: relative;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);

    .p-tag.stream-control {
      position: absolute;
      top: -17px;
      right: 12px;

      &.p-tag-info {
        background: var(--gray-50);
        border: 1px solid var(--gray-100);
        color: var(--gray-600);
      }
    }
  }

  &__teams {
    display: flex;
    align-items: center;
    align-self: stretch;
  }

  &__team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;

    &-name {
      overflow: hidden;
      color: #465161;
      text-align: center;
      text-overflow: ellipsis;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16.5px;
    }

    &_empty {
      span {
        color: #9FA9B7 !important;
      }
    }

    span {
      color: #6466E9;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__period {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    padding-top: 1rem;
    border-top: 1px solid #DFE7EF;

    span {
      color: #9FA9B7;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
    }
  }

    .ticker-node {
        font-weight: 600;
        color: var(--bluegray-800);
    }

    .item-top {
        display: flex;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        height: 106px;
        align-items: flex-end;
        padding: 12px;
        box-sizing: border-box;
        margin-top: 24px;
        position: relative;
        justify-content: space-between;
        z-index: 1;

        .p-tag.stream-control {
            position: absolute;
            top: -17px;
            right: 12px;

            &.p-tag-info {
                background: var(--gray-50);
                border: 1px solid var(--gray-100);
                color: var(--gray-600);
            }
        }

        .p-tag-secondary {
            position: absolute;
            top: -17px;
            background: var(--blue-50);
            color: var(--blue-400);
        }

        .subject-emblem {
            position: absolute;
            top: -20px;
            left: calc((100% - 64px)/2);
        }

        .team {
            display: flex;
            width: 120px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .p-skeleton:last-child {
                margin-top: 6px;
            }

            .p-tag {
                background: var(--bluegray-50);
                color: var(--bluegray-700);
                padding: 2px 7px 0px;
                text-align: center;
                font-size: 11px;
                margin-top: 6px;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
            }
        }

        .meta {
            width: calc(100% - 240px);
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .date {
                font-size: 10.5px;
                font-weight: 300;
                color: var(--bluegray-900);
            }

            .time {
                font-size: 12px;
                font-weight: 500;
                color: var(--bluegray-800);
                margin-top: 4px;
            }
        }
    }

    .timeline-loader {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        height: 160px;

        .p-skeleton.line {
            position: absolute;
        }
    }

    .btns-loader {
        position: absolute;
        bottom: 12px;
        left: 12px;
        right: 12px;

        .p-skeleton:last-child {
            margin-top: 8px;
        }
    }

    .item-actions {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: #fff;
        padding: 12px;
        box-shadow: 1px 1px 22px rgba(0, 0, 0, 0.06);
        z-index: 99;

        .p-button {
            width: 100%;

            &.p-button-secondary {
                margin-top: 12px;
                background: var(--bluegray-50);
                border: none;
                color: var(--bluegray-500);
            }

            &.p-button:first-child {
                margin-top: 0 !important;
            }

            .pi {
                margin-right: 8px;
            }
        }
    }

    .item-timeline {
        margin-top: 30px;
        transition: all .5s linear;

        &.launched {
            //margin-top: -46px;
            z-index: 0;
        }

        &.finished {
            margin-top: -88px;
            z-index: 0;
        }

        &.post-mode {
          margin-top: 0 !important;
        }

        .p-timeline-event-marker {
            border-color: var(--blue-500);
        }

        .marker {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 14px;
            border: 2px solid;

            &.pi-check {
                background: var(--green-500);
                color: var(--green-50);
                border-color: var(--green-200);
            }

            &.pi-cog {
                background: var(--orange-50);
                color: var(--orange-400);
                border-color: var(--orange-100);
            }
        }


        .p-tag {
            margin-top: 3px;
            background: var(--blue-500);
            min-width: 100px;

            &.disabled {
                background: var(--bluegray-100);
                color: var(--bluegray-500);
            }

            &.ready {
                background: var(--green-100);
                color: var(--green-700);
            }

            &.progress {
                background: var(--orange-100);
                color: var(--orange-700);
            }
        }
    }
}
