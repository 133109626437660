.list-nav {
    .top {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        min-height: 92px;
        align-items: flex-end;
        padding: 12px 12px 6px;
        padding-top: 46px;

        .logout {
            position: absolute;
            right: 12px;
            top: 15px;
        }

        .controls {
            display: flex;
            gap: .75rem;
            width: 100%;
            padding-bottom: 6px;

            &>div {
                width: 50%;

                .p-dropdown {
                    width: 100%;
                    border: 1px solid var(--blue-400);
                    background: var(--blue-500);

                    &:focus, &.p-inputwrapper-focus {
                        outline: none;
                        box-shadow: none;
                    }

                    .p-dropdown-label {
                        padding: .5rem .75rem .45rem;
                        font-size: .8rem;
                        color: var(--blue-50);
                        flex: unset;
                        width: 80%;
                    }

                    .p-dropdown-trigger {
                        width: 20%;
                    }

                    .pi {
                        color: var(--blue-50);
                    }
                }
            }
        }

        .p-tag {
            margin-right: 8px;
            margin-bottom: 6px;
            background: var(--blue-50);
            color: var(--blue-500);
            transition: all 0.4s ease-in-out;
            white-space: nowrap;
            font-weight: 600;
            border: 1px solid var(--blue-100);
            padding: .35rem .6rem;

            &.active {
                background: var(--blue-500);
                color: var(--blue-50);
            }
        }
    }

    .empty-list {
        display: flex;
        flex-direction: column;
        height: 300px;
        justify-content: center;
        align-items: center;

        i {
            font-size: 44px;
            margin-bottom: 30px;
            color: var(--bluegray-300);
        }

        div {
            color: var(--bluegray-700);
        }
    }

    .list-bars {

        &>div:first-child {
            padding: 0 12px 12px;
        }
    }

    .tour-list {
        display: flex;
        flex-direction: column;

        .list-card {
            background: #fff;
            border-radius: 6px;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
            margin-top: 10px;
            box-sizing: border-box;
            display: flex;
            position: relative;
            padding: 15px 12px 12px;
            width: calc(100vw - 24px);

            .executive-tag {
                position: absolute;
                background: var(--blue-50);
                color: var(--blue-500);
                top: -7px;
                padding: 2px 7px 1px;
                // font-weight: normal;
                font-size: 11px;
                right: 12px;
            }

            .team {
                width: 120px;
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                .p-tag {
                    background: var(--bluegray-50);
                    color: var(--bluegray-700);
                    padding: 2px 7px 0px;
                    text-align: center;
                    font-size: 11px;
                    margin-top: 6px;
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                }
            }

            .meta {
                width: calc(100% - 240px);
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                .score-tag {
                    margin-top: 3px;
                    padding: 1px 11px;
                }

                .state-label {
                    font-size: 11px;
                    color: var(--bluegray-500);
                    text-align: center;
                    margin-top: 4px;
                }

                .date {
                    font-size: 10.5px;
                    font-weight: 300;
                    color: var(--bluegray-900);
                }

                .time {
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--bluegray-800);
                    margin-top: 4px;
                }
            }
        }
    }
}
