.chips-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    gap: 12px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    &__title {
        color: #495057;
        font-size: 17.5px;
        font-style: normal;
        font-weight: 800;
        line-height: 26.25px;
    }

    .p-inputgroup-addon {
        color: #495057;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        border: 1px solid #ced4da;
        background: #f7f8f9;
    }

    .p-inputtext {
        border-radius: 0 6px 6px 0;
    }

    .p-button {
        width: 38px;
        padding: 11px;
    }
}
