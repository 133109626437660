.verifyModal {
    .side-profile__accordion-title {
        &.declain {
            color: #e50076;
        }

        &.accept {
            color: #0bb797;
        }
    }

    .side-profile__accordion-declain-text {
        color: var(--Lara-Global-textColor, #495057);
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    .auth2__button_outlined {
        border: 1px solid #e50076 !important;
        span {
            color: #e50076;
        }
        &:hover {
            border: 1px solid #e50076 !important;
        }
    }

    &__dialog {
        textarea {
            width: 100%;
            resize: vertical;
            min-height: 104px;

            &::placeholder {
                color: var(--Lara-Input-Field-inputIconColor, #6c757d);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px; /* 150% */
            }
        }
        .p-dialog-mask {
            z-index: 999999999999;

            .p-button-label {
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px; /* 121.429% */
            }
        }

        .p-dialog {
            width: 620px !important;
        }

        .p-dialog-title {
            color: var(--Lara-Dialog-dialogHeaderTextColor, #343a40);
            font-size: 17.5px;
            font-style: normal;
            font-weight: 800;
            line-height: 21px; /* 120% */
        }

        .p-dialog .p-dialog-content {
            padding: 0 21px 28px 21px;
        }
    }
}
