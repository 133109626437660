.reportage-panel {
  display: flex;
  overflow: scroll;
  max-height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  padding: 32px 0 86px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;

    color: #465161;
    font-family: Inter, sans-serif;
    font-size: 16.602px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    span {
      color: #6C757D;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px; /* 150% */
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    label {
      color: #495057;
      font-family: "Inter V", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__inputtextarea {
    display: flex;
    min-height: 206px;
    padding: 10.5px 7px 10.5px 10.5px;
    align-items: flex-start;
    gap: 7px;
    align-self: stretch;

    &::placeholder {
      color: #6C757D;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }

  &__fileupload {
    width: 100%;

    .p-button {
      display: flex;
      width: 100%;
      height: 226px;
      padding: 18px;
      justify-content: center;
      align-items: center;
      gap: 7px;

      border-radius: 6px;
      border: 1px solid #CED4DA;
      background: #FFF;

      &:hover, &:active {
        background: #FFF !important;
      }

      &-icon {
        color: #BDBDBD;
      }

      &-label {
        display: none;
      }
    }
  }

  &__photo {
    height: 226px;
    width: 100%;
    object-fit: contain;
    border: 1px solid #CED4DA;
    background: #FFF;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 20px;
    z-index: 10;
    //flex-direction: column;
    //justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;

    background: var(--Lara-Surface-surface-0, #FFF);
    box-shadow: 1px 1px 22px 0px rgba(0, 0, 0, 0.20);
  }

  &__button {
    display: flex;
    padding: 8.5px 17.5px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    flex: 1 0 0;
    width: 100%;

    border-radius: 6px;
    border: 1px solid var(--Lara-Button-buttonBorder, #6466E9) !important;
    background: var(--Lara-Button-buttonBg, #6466E9) !important;
    color: #FFF !important;

    .p-button-icon {
      font-size: 10px;
      margin-right: 0 !important;
    }
  }
}