.calendar-matchday {
  display: flex;
  padding: 36px 0;
  flex-direction: column;
  align-items: flex-start;
  min-width: calc(50% - 12px);
  max-width: calc(50% - 12px);
  grid-gap: 24px;
  flex: 1 0 0;

  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);

  &__header {
    display: flex;
    width: 100%;
    height: 38px;
    padding: 0px 36px 24px;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    align-self: stretch;

    border-bottom: 1px solid #DFE7EF;

    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 800;
    line-height: 26.25px;
  }

  &__matches {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  }

  &__match {
    display: flex;
    padding: 0 36px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    position: relative;
    cursor: pointer;

    &:hover {
      background: var(--Lara-Indigo-indigo-50, #F7F7FE);

      .calendar-matchday__arrow {
        opacity: 1;
      }
    }

    &_editor {
      gap: 0;
    }

    .p-dropdown {
      flex: 1 0 0;
      width: calc(50% - 79px);
      min-height: 47px;

      img {
        width: 20px;
        height: 20px;
      }

      .p-dropdown-label-empty {
        visibility: visible;
      }
    }
  }

  &__team {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    flex: 1 0 0;

    border-radius: 6px;
    border: 1px solid rgba(206, 212, 218, 0.00);

    width: calc(50% - 79px);

    &_editor {
      justify-content: flex-start;
      width: 100%;
      color: #6C757D;
    }

    &-name {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      color: #495057;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }

    img {
      width: 36px;
      height: 36px;
      object-fit: cover;
    }
  }

  &__score {
    display: flex;
    height: 62px;
    position: relative;

    width: 79px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 4px;
    background: #F7F8F9;

    color: #6C757D;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;

    &_editor {
      background: none;
    }
  }

  &__group {
    display: flex;
    padding: 3.5px 5.6px;
    align-items: flex-start;
    grid-gap: 3.5px;

    position: absolute;
    top: -12px;
    border-radius: 6px;
    background: #FFF;

    color: #818EA1;
    font-family: Inter, sans-serif;
    font-size: 10.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.8px;
  }

  &__arrow {
    display: flex;
    height: 42px;
    width: 21px;
    justify-content: center;
    align-items: center;
    gap: 14px;
    position: absolute;
    right: 8px;
    border-radius: 6px;
    background: #6466E9;
    padding: 5.485px 7.219px 5.469px 8.094px;
    cursor: pointer;
    opacity: 0;

    .pi {
      color: white;
      font-size: .75rem;
    }


  }
}