.search-panel {
  display: flex;
  flex-direction: column;
  grid-gap: 10.5px;
  width: 100%;
  height: max-content;

  &__overlay {
    align-items: flex-start !important;
    position: fixed;
    top: 0;
    right: 0;
    height: 90vh;
    width: calc(100% - 16vw);
    z-index: 99999999;
    background: rgba(40, 41, 96, .24);
    padding: 16px 126px 16px 36px;
  }

  &__input {
    display: flex;
    height: 42px;
    padding: 10.5px 0 10.5px 10.5px;
    align-items: center;
    gap: 7px;
    width: 100%;
    border-radius: 6px;
    background: var(--Lara-Input-Field-inputBg, #FFF);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 0px 0px 2.8px #C9D2FA;
  }

  &__results {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 434px;
    padding: 24px 24px 62px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 14px;
    flex-shrink: 0;

    border-radius: 6px;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .10);
  }

  &__select {
    display: flex;
    width: 100%;
    border-radius: 6px !important;
    box-shadow: none !important;

    .p-button {
      flex: 1 0 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      background-color: #DFE7EF;
      border-radius: 10px;
    }

    color: #9FA9B7;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;

    &_empty {
      justify-content: center;
      text-align: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 20px 0;
    grid-gap: 6px;

    border-bottom: 1px solid #DFE7EF;
  }

  &__title {
    flex: 1 0 0;
    width: 100%;
    color: #9FA9B7;
    font-feature-settings: 'case' on;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__object {
    display: flex;
    width: calc(33% - 24px);

    height: 48px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    cursor: pointer;

    img {
      width: 36px;
      height: 36px;
      object-fit: contain;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
      flex: 1 0 0;

      overflow: hidden;
      color: var(--Lara-Input-Field-inputTextColor, #495057);
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      align-self: stretch;

      span {
        color: #9FA9B7;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__objects {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    grid-gap: 24px;
    wrap-option: wrap;
    flex-wrap: wrap;
  }

  &__show-all {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
    min-height: 38px;
    border: 1px solid #6466E9;
    color: #6466E9;
    background: white;
  }
}