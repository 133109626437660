.circle-structure {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__card {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &.teams {
            padding: 0;
        }

        .p-buttonset {
          display: flex;
          border-radius: 0;
          box-shadow: none;
          width: 100%;

          .p-button {
            flex: 1 0 0;
          }
        }

    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        &.teams {
            padding: 24px 24px 0;
        }
    }

    &__hint {
        background: #f7f8f9;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #818ea1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
    }

    &__title {
        color: #495057;
        font-size: 17.5px;
        font-style: normal;
        font-weight: 800;
        line-height: 26.25px;
    }

    &__input {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: column;

        &-label {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }

        .p-inputtext {
            width: 100%;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;

            &::placeholder {
                color: #6c757d;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
            }
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__chips {
        padding: 0 24px 24px;
        box-sizing: border-box;
    }

    .p-autocomplete {
        width: 100%;
    }

    .p-inputtext {
        width: 100%;
    }

    .p-chips {
        width: 100%;
        height: 202px;
        padding: 0.375rem 0.75rem;
        border-radius: 6px;
        border: 1px solid #ced4da;

        .p-inputtext {
            width: 100%;
            padding: 0;
            border: 0;
            height: min-content;
            box-shadow: unset !important;
        }
    }

    &__score {
        &-item {
            display: flex;
            align-items: center;

            & .p-inputnumber {
                display: inline-block;
                border-radius: 0 6px 6px 0;
                width: 75px;
                height: 100%;
                min-height: 36px;
            }

            & .p-inputnumber-input {
                height: 100%;
            }
        }

        &-name {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            padding: 10px 10px 10px 20px;
            box-sizing: border-box;
            border-radius: 6px 0 0 6px;
            border: 1px solid #ced4da;
            background: #f7f8f9;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            width: 100%;
            min-height: 36px;
        }
    }

    &__btn {
        position: fixed;
        bottom: 1.87rem;
        right: 4.37rem;
    }

    .p-autocomplete-multiple-container {
        gap: 10px;

        .p-autocomplete-token {
            margin: 0;
        }
    }

    .p-autocomplete-panel {
        top: 100% !important;
        max-height: 156.5px !important;
    }
}

.p-overlaypanel-content {
    padding: 0 !important;
    .circle-structure__overlay {
        max-height: 355px;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 5px;
            height: 3px;
            margin-left: 30px;
        }

        &::-webkit-scrollbar-thumb {
            height: 50px;
            background-color: var(--Lara-Root-surface-border, #dfe7ef);
            border-radius: 10px;
        }
    }
    .p-inputnumber-input {
        border-radius: 0 6px 6px 0;
        width: 75px;
    }
}
