.share-lineup-panel {

    .share-image {
        margin: 20px 0 80px;

        img {
            width: 100%;
            border-radius: 12px;
            box-shadow: 1px 1px 20px var(--blue-100);
        }
    }
}
