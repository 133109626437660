.reg-success-person-data {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1.25rem;

  &__title {
    margin-bottom: .75rem;
  }

  &__role {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: .5rem;
    align-self: stretch;

    span {
      font-family: Inter, sans-serif;
      font-size: 1.09375rem;
      font-weight: 700;
      line-height: 1.3125rem;
    }

    color: #495057;
    font-family: "Inter V", sans-serif;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1.25rem;
    align-self: stretch;
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.25rem;
    align-self: stretch;
  }

  &__subtitle {
    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 1.09375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3125rem;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0.75rem;
    align-self: stretch;
  }

  input {
    color: #495057 !important;
    font-size: .875rem;
  }

  .p-dropdown .p-dropdown-label {
    font-size: .875rem;
  }


}

.p-tooltip {
  max-width: 429px;
}


.p-tooltip-text {
  text-align: center;
}