.leagues-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    &__header {
        border-radius: 10px;
        background: #fff;
        padding: 24px;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;


    }

    & .p-buttonset {
        box-shadow: none;
        overflow: unset;

        & .p-button {
            padding: 10.5px 17.5px;
            min-height: 39px;
        }
    }

    &__button {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        grid-gap: 0.5rem;
        position: fixed;
        bottom: 1.87rem;
        right: 4.37rem;
        margin-top: 24px;
        margin-left: auto;
        z-index: 99;
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 12px;

        & .mediabank {

            &__upload {
                border-radius: 6px;

                & .fileupload__input {
                    padding: 0;
                    border: none !important;
                }

                & .p-button {
                    height: 100%;
                    display: inline-flex;
                    min-height: 39px;
                    padding: 10.5px 32px !important;
                    background: #6466E9 !important;
                }

                & .p-button-label {
                    display: block !important;
                    width: auto !important;
                    opacity: 1 !important;
                    color: var(--Lara-Button-buttonTextColor, #FFF);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                & .p-button-icon {
                    display: none;
                }
            }

            &__size {
                flex: 1 0 auto;
                min-width: 120px;
                
                &-text {
                    color: var(--Lara-Blue-Gray-bluegray-300, #9FA9B7);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                }

                &-progress {
                    margin-top: 6px;

                    &.p-progressbar {
                        height: 4px;
                    }

                    & .p-progressbar-label {
                        display: none;
                    }
                }
            }
        }
    }

    &__dialog {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--Lara-Global-textColor, #495057);
            font-feature-settings: "liga" off, "clig" off;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 133.333% */
        }
    }

    .p-dialog {
        max-width: 890px;
        width: 100%;
        // height: 700px;

        &-header {
            display: flex;
            gap: 12px;
            align-items: center;

            &-icons {
                align-self: unset;
            }
        }

        &-content {
            padding: 0 !important;
            // border-radius: 10px !important;

            border-radius: 0 0 6px 6px !important;
        }

        .data-table {
            .p-datatable-table {
                border-radius: 0;
            }
            .p-paginator {
                position: static;
                border-top: 1px solid #dfe7ef;
                padding-bottom: 24px;
            }
        }
    }
}
