.notices {
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-gap: 24px;

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 32px 24px;
    align-self: stretch;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  }

  &__options {
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }

  &__select {
    border-radius: 6px !important;
    box-shadow: none !important;
  }
}