.notices-modal {
  &__side {
    min-width: 38% !important;
  }

  &__button_outlined {
    background: unset !important;

    span {
      color: #6466E9;
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }

    &:hover {
      opacity: .85;
      border: 1px solid #6466E9;
      background: unset !important;
    }
  }

  &__type {
    color: #495057;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__notice {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    border-radius: 6px;
    background: #F7F8F9;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }

    &-text {
      width: 100%;
      color: #495057;
      font-variant-numeric: lining-nums tabular-nums;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      white-space: pre-line;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-title {
      color: #495057;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    &-date {
      color: #9FA9B7;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-showall {
      cursor: pointer;
      color: #818EA1;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    align-self: stretch;
  }

  &__header {
    color: #495057;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__select {
    border-radius: 6px !important;
    box-shadow: none !important;
    align-self: stretch;

    .p-button {
      width: 50%;
      color: #6466E9 !important;
      border-color: #6466E9 !important;

      &.p-highlight {
        color: #FFF !important;
        background: #6466E9 !important;
      }
    }
  }

  &__textarea {
    height: 462px !important;
  }
}