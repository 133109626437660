.payments {
    .side-profile {
        &__accordion {
            &-payments {
                display: none;
                flex-direction: column;
                grid-gap: 12px;
                align-self: stretch;
            }

            &_active {
                .side-profile__accordion {
                    &-payments {
                        display: flex;

                        &.empty {
                            display: flex;
                            height: 400px;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    &__result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        color: #818ea1;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 6px;
        background: var(--Lara-Blue-Gray-bluegray-50, #f7f8f9);

        &-rub {
            color: #495057;
            font-feature-settings: "liga" off, "clig" off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        align-self: stretch;
    }
    &__title {
        color: #495057;
        font-feature-settings: "liga" off, "clig" off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    &__empty {
        align-self: center;
        color: #6c757d;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    .p-buttonset {
        box-shadow: none;
        overflow: unset;
        display: flex;
    }

    .p-inputgroup {
        .p-dropdown {
            flex: unset;
            width: 30%;
            background: #f7f8f9;
        }
    }

    .p-multiselect-label {
        color: var(--Lara-Input-Field-inputTextColor, #495057);
        font-family: "Object Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        max-width: 450px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
    }

    .p-selectbutton .p-button {
        border-color: #6466e9;
        color: #6466e9;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        text-align: center;
        justify-content: center;

        &.p-highlight {
            background: #6466e9;
            color: #fff;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .payments__back {
        background: transparent !important;
        color: #9FA9B7 !important;
        border: 0;
    }
}
