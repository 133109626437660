.auto-circle {
  display: flex;
  padding: 24px 0;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 24px;
  align-self: stretch;

  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

  &__header {
    display: flex;
    padding: 0 24px;
    align-items: center;
    grid-gap: 1px;
    align-self: stretch;

    color: #495057;
    font-family: Inter, sans-serif;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 800;
    line-height: 26.25px;
  }

  &__content {
    display: flex;
    padding: 0 24px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 24px;
    align-self: stretch;
  }

  &__select {
    display: flex;
    width: 100%;
    min-width: 330px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    flex: 1 0 0;

    &-label {
      color: #495057;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    &-dropdown {
      width: 100%;
    }
  }
}