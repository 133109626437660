.mediaForm-detail {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;
    padding: 0 22px;
    
    & .p-card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    & .card {
        padding: 24px;
        
        & .p-card-body {
            padding: 0;
        }
    }
    
    &__categories {
        color: #495057;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        display: flex;
        flex-direction: column;
        gap: 9.25px;

        div:first-child {
            display: flex;
            align-items: center;
            gap: 5.5px;
        }
        
        &-item {
            color: #6366F1;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            padding: 3px 6px;
            border-radius: 6px;
            background: #F7F7FE;
            transition: .3s ease;
            
            @media (hover: hover){
                &:hover{
                    cursor: pointer;
                    opacity: .8;
                }
            }
            
            &.active {
                color: #F7F7FE;
                background: #6366F1;
            }
        }
    }
    
    &__buttons {
        position: fixed;
        right: 36px;
        bottom: 33.5px;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.publication-form {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 20px;

    .custom-upload {
        width: 100%;
        display: flex;
        gap: 1rem;

        .upload-section {
            width: 320px;
            background: var(--indigo-50);
            border-radius: 1rem;
            border: 1px solid var(--indigo-100);
            box-sizing: border-box;
            padding: .75rem;
            padding-bottom: 5rem;
            position: relative;

            .upload-action {
                position: absolute;
                bottom: .75rem;
                left: .75rem;
                right: .75rem;

                .p-button {
                    margin: 0;
                    width: 100%;
                    padding: .75rem;
                    height: 40px;
                    justify-content: center;

                    background: var(--indigo-100);
                    color: var(--indigo-400);
                    border: 1px solid var(--indigo-200);

                    span {
                        font-size: .75rem;
                        flex: none;
                    }
                }

                & .media-bank__dialog {
                    max-width: 1000px;

                    & .p-dialog-content {
                        padding: 0;
                    }

                    & .p-dialog-footer {
                        & > div:first-child{
                            display: flex;
                            justify-content: flex-end;
                        }

                        & .p-button {
                            width: unset;
                        }
                    }

                    & .media-bank__images {
                        grid-template-columns: repeat(4, minmax(100px, auto));
                    }
                }
            }

            .subtitle {
                font-weight: 600;
                margin-bottom: .5rem;
            }

            .notice {
                font-size: .75rem;
                color: var(--indigo-700);
            }

            .p-fileupload {

                .p-component-fileupload-choose {
                    padding: 0;
                }
            }
        }
    }

    .publication-action {
        position: absolute;
        z-index: 50;
        top: 20px;
        left: 190px;
        width: 250px;

        .p-button {
            margin: 0;
            width: auto;
        }

        &.stored {
            left: 20px;
        }
    }

    input {
        width: 100%;
    }

    .p-editor-container {
        margin-top: 8px;
    }

    .empty {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: 120px;
        aspect-ratio: 16 / 9;
        background: var(--bluegray-50);
        border-radius: 8px;

        .pi {
            font-size: 18px;
        }

        div {
            font-size: 11px;
            margin-top: 6px;
        }
    }

    .p-fileupload-choose {
        height: 67px;
        background: var(--bluegray-50);
        border-radius: 8px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;

        color: #6366F1;

        background: #F7F7FE;
        border: none;

        .p-fileupload-choose-icon {
            width: 21px;
            height: 21px;
            color: #6366F1;
            font-weight: 700;
        }
    }

    .form-settings {
        display: flex;
        padding-top: 16px;
        align-items: center;

        &>div {
            &:first-child {
                width: 20%;
            }

            &:nth-child(2) {
                width: 30%;
                box-sizing: border-box;
                padding-right: 20px;
            }

            &:last-child {
                width: 50%;
                padding-bottom: 6px;
            }
        }

        .p-checkbox-label {
            margin-left: 8px;
            font-size: 14px;
        }
    }

    .category-control {
        padding-top: 2px;

        .category-tag {
            font-size: .8rem;
            font-weight: 300;
            padding: 2px 6px 4px;
            margin-right: .5rem;
            transition: all 0.4s linear;
            background: var(--indigo-50);
            color: var(--indigo-500);

            &:hover {
                cursor: pointer;
                background: var(--indigo-100);
            }

            &.selected {
                background: var(--indigo-500);
                color: var(--indigo-50);
            }
        }
    }
}
