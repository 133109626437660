.tournamentsReqests-list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header__title {
        padding: 0;
    }

    &__search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        position: relative;
        padding: 0 24px 20px;
        border-bottom: 1px solid #dfe7ef;

        input {
            width: 100%;
        }

        .pi-search {
            position: absolute;
            right: 36px;
        }
    }

    &__notfound {
        color: #6c757d;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        top: calc(50vh - 150px);
    }
}

.tournamentsReqests-list__dialog {
    .p-dialog-content {
        padding: 0;

        &:last-of-type {
            padding: 0;
        }
    }

    .p-accordion {
        max-height: 600px;
        overflow: auto;
        padding: 0 24px;

        &::-webkit-scrollbar {
            width: 5px;
            height: 3px;
            margin-left: 30px;
        }

        &::-webkit-scrollbar-thumb {
            height: 50px;
            background-color: var(--Lara-Root-surface-border, #DFE7EF);
            border-radius: 10px;
        }

        &-header {
            &-link {
                flex-direction: row-reverse;
                padding: 0;
                background: transparent !important;
                border: none !important;
                padding: 24px 0 0 !important;

                &:focus {
                    box-shadow: unset !important;
                }
            }

            &-text {
                width: 100%;
                color: #495057;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 34px;
                display: flex;
                align-items: center;
                gap: 12px;

                & > img {
                    width: 64px;
                    height: 64px;
                }
            }
        }

        &-content {
            border: 0 !important;
            padding: 0 !important;
        }

        &-tab {
            border-bottom: 1px solid #DFE7EF;
        }
    }

    .tournamentsReqests-list__tabs {
        display: flex;
        flex-direction: column;
        gap: 6px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 32px;
            top: -10px;
            bottom: 0;
            width: 1px;
            background: #dfe7ef;
        }
    }

    .tournamentsReqests-list__tab {
        &:last-of-type {
            padding-bottom: 24px;
        }

        & > img {
            width: 32px;
            height: 32px;
        }

        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 17px;

        &-container {
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 8px;
            background: #fafafa;
            color: #6c757d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        &-title {
            color: #495057;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.column__overlay {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &-item {
        padding: 14px 14px 0;
        box-sizing: border-box;
        transition: 0.3s;

        &:last-of-type {
            padding: 14px;
        }

        @media (hover: hover) {
            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
}
