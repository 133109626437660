.p-editor-container {
  width: 100%;
  height: 100%;
}

.p-editor-toolbar {
  background: var(--lara-input-lists-inputlistheaderbg);
  border: 1px solid var(--lara-global-divider);
  border-radius: .38rem .38rem 0 0;
  padding: .62rem .88rem;
}

.p-editor-content {
  height: 5.56rem !important;
  max-height: 5.56rem;
  border: 1px solid var(--lara-global-divider);
  border-radius: 0 0 .38rem .38rem;

  background: var(--lara-root-surface-section);

  cursor: text;
}

.ql-editor {
  // display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: 10.5px;
  grid-gap: 14px;
  width: 100%;
  height: 5.56rem !important;
  max-height: 5.56rem;

  border-radius: 6px;

  // color: var(--Lara-Global-textColor, #495057) !important;
  color: var(--Lara-Input-Field-inputTextColor, #495057);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  input {
    border-radius: 0;
  }
}