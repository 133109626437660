.match-dialog {
    border-radius: 12px;
    overflow: hidden;
}

.number-dialog {
    padding-bottom: 30vh;
}

.p-confirm-popup {
    box-shadow: 0px 4px 15px rgba(59, 130, 246, 0.1);

    .p-confirm-popup-content {
        max-width: 320px;
        flex-direction: column;

        .p-confirm-popup-message {
            margin-left: 0;
            font-size: 14px;
            color: var(--bluegray-700);
            line-height: 1.4;
            text-align: center;
        }
    }

    .p-confirm-popup-footer {
        width: 320px;
        display: flex;
        flex-direction: column;

        .p-button {
            width: 100%;

            &:first-child {
                margin-bottom: 12px;
            }

            &.p-confirm-popup-reject {
                background: var(--bluegray-50);
                border: 1px solid var(--bluegray-100);
                color: var(--bluegray-300);
            }

            &.p-confirm-popup-accept {
                background: var(--indigo-50);
                border: 1px solid var(--indigo-100);
                color: var(--indigo-500);
            }
        }
    }
}

.create-dialog {

    .p-dialog-header {

        .p-dialog-title {
            text-align: left;
            font-weight: normal;
        }
    }

    p.notice {
        max-width: 360px;
        line-height: 23px;
    }

    .create-dialog_body {
        width: 400px;

        .control {
            margin-bottom: 16px;
            position: relative;

            .p-dropdown, .p-inputtext, .p-calendar {
                width: 100%;
            }

            .p-dropdown.p-disabled {
                .p-dropdown-trigger {
                    opacity: 0.25;
                }
            }

            .nested-marker {
                position: absolute;
                display: flex;
                width: 32px;
                height: 32px;
                background: var(--blue-50);
                color: var(--blue-100);
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                z-index: 5;
                bottom: -22px;
                left: calc((100% - 32px)/2);
            }
        }

        .section {
            margin-top: 0px;
            border-top: 1px solid var(--blue-50);
            padding-top: 10px;

            .p-dropdown, .p-inputtext, .p-calendar  {
                width: 100%;
            }

            .field-radiobutton {
                height: 32px;
                align-items: center;

                &:hover {
                    cursor: pointer;

                    label {
                        cursor: pointer;
                    }
                }

                label {
                    margin-left: 11px;
                }
            }
        }
    }

    .p-dialog-footer {

        .create-form_actions {
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: start !important;
            .p-button {
                background: var(--indigo-50);
                color: var(--indigo-500);
                border-color: var(--indigo-100);

                &.p-button-danger {
                    background: var(--red-50);
                    color: var(--red-200);
                    border-color: var(--red-100);
                }

                &.p-button-secondary {
                    background: var(--bluegray-50);
                    color: var(--bluegray-300);
                    border-color: var(--bluegray-100);
                }

                .pi {
                    margin-right: 6px;
                }
            }
        }
    }
}
