.side-profile {
    width: 32% !important;
    display: flex;
    flex-direction: column;
    height: 93vh !important;
    margin-top: 7vh;
    background: #fff;

    &_no-verify {
      height: 100vh !important;
      margin-top: 0;
    }

    &__overlay {
        z-index: 901 !important;
    }

    .side-profile__accordion-title {
        &.declain {
            color: #e50076;
        }

        &.accept {
            color: #0bb797;
        }
    }

    .side-profile__accordion-declain-text {
        color: var(--Lara-Global-textColor, #495057);
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    &__header {
        width: 100%;
        display: flex;
        padding: 0.75rem 1.5rem;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        grid-gap: 0.625rem;

        border-bottom: 1px solid #dfe7ef;
        background: #fff;

        &-btn-group {
            display: flex;
            align-items: center;
            grid-gap: 1rem;
        }
    }

    &__button-save {
        width: max-content !important;
        padding: 0.3rem 0.75rem !important;
    }

    &__button-block {
        width: max-content !important;
        border: 1px solid #e50076 !important;
        padding: 0.3rem 0.75rem !important;

        .p-button-label {
            color: #e50076 !important;
        }
    }

    &__button-unblock {
        width: max-content;
        border: 1px solid #0ad9b3 !important;
        padding: 0.3rem 0.75rem !important;

        .p-button-label {
            color: #0ad9b3 !important;
        }
    }

    &__icon-close {
        cursor: pointer;
        color: #9fa9b7;
        margin-left: auto;
    }

    &__body {
        display: flex;
        height: calc(100% - 60px);
        width: 100%;
        padding: 1.25rem 1.5rem 0 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 1.25rem;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5px;
            height: 3px;
            margin-left: 30px;
        }

        &::-webkit-scrollbar-thumb {
            height: 50px;
            background-color: var(--Lara-Root-surface-border, #dfe7ef);
            border-radius: 10px;
        }
    }

    &__account {
        display: flex;
        padding-bottom: 1.25rem;
        align-items: center;
        align-self: stretch;
        grid-gap: 1rem;

        border-bottom: 1px solid #dfe7ef;

        &-info {
            display: flex;
            width: 100%;
            align-items: flex-start;
            grid-gap: 0.25rem;
        }

        &-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 0.25rem;
            flex: 1 0 0;
        }

        &-main {
            display: flex;
            grid-gap: 0.125rem 0.75rem;
            align-self: stretch;
            flex-wrap: wrap;
            flex-direction: column;

            color: #495057;
            font-feature-settings: "clig" off, "liga" off;
            font-family: Inter, sans-serif;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;
        }

        &-bday {
            display: flex;
            align-items: center;
            align-content: center;
            grid-gap: 0.125rem 0.5rem;
            align-self: stretch;
            flex-wrap: wrap;

            color: #9fa9b7;
            font-variant-numeric: lining-nums tabular-nums;
            font-family: Inter, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;

            span {
                color: #495057;
                font-variant-numeric: lining-nums tabular-nums;
                font-family: Inter, sans-serif;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;
            }
        }

        &-edit {
            cursor: pointer;
        }
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        min-height: 4rem;
        background-color: #3d75e3;
        border-radius: 100%;

        object-fit: cover;

        color: #fff;
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Inter, sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }

    &__tag {
        display: flex;
        padding: 0.21875rem 0.35rem;
        align-items: center;
        grid-gap: 0.21875rem;
        width: max-content;

        border-radius: 0.375rem;
        background-color: #effff5 !important;

        color: #0ba186 !important;
        font-family: Inter, sans-serif;
        font-size: 0.65625rem;
        font-style: normal;
        font-weight: 600;
        line-height: 0.9875rem;

        &_blue {
            background-color: #dadafc !important;
            color: #6366f1 !important;
        }

        &_gray {
            background-color: #dadee3 !important;
            color: #818ea1 !important;
        }
    }

    &__accordion {
        display: flex;
        padding-bottom: 1.25rem;
        flex-direction: column;
        grid-gap: 1rem;
        align-self: stretch;
        border-bottom: 1px solid #dfe7ef;

        &_active {
            .side-profile__accordion {
                &-role,
                &-doc,
                &-verify {
                    display: flex;
                    padding: 1rem;
                }
                &-role {
                    display: flex;
                    padding: 1rem;
                }
            }
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-self: stretch;

            i {
                cursor: pointer;

              &.disabled {
                opacity: .2;
                cursor: auto;
              }
            }
        }

        &-title {
            display: flex;
            align-items: center;
            grid-gap: 0.5rem;

            color: #495057;
            font-feature-settings: "clig" off, "liga" off;
            font-family: Inter, sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;

            i {
                cursor: pointer;
                color: rgba(100, 102, 233, 1);
            }
        }

        &-role {
            display: none;
            width: 100%;
            flex-direction: column;
            grid-gap: 1rem;

            border-radius: 0.375rem;
            background: #f7f8f9;
        }

        &-subtitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            color: #495057;
            font-feature-settings: "clig" off, "liga" off;
            font-family: Inter, sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;

            i {
                cursor: pointer;
            }
        }

        &-subtext {
            display: flex;
            align-items: center;
            align-content: center;
            grid-gap: 0.125rem 0.5rem;
            align-self: stretch;
            flex-wrap: wrap;

            p {
                flex: 1 0 0;
                color: #9fa9b7;
                font-variant-numeric: lining-nums tabular-nums;
                font-family: Inter, sans-serif;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;

                margin: 0;
            }

            span {
                flex: 1 0 0;
                color: #495057;
                font-variant-numeric: lining-nums tabular-nums;
                font-family: Inter, sans-serif;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;
            }
        }

        &-doc {
            display: none;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            border-radius: 0.375rem;
            background: #f7f8f9;

            .pi-ellipsis-v {
                cursor: pointer;
            }

            &-info {
                display: flex;
                align-items: center;
                grid-gap: 1.25rem;

                i {
                    font-size: 1.25rem;
                    padding: 1rem;
                    background-color: white;
                    border-radius: 100%;

                    &.pi-check {
                        color: #0ad9b3;
                    }
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                color: #9fa9b7;
                font-variant-numeric: lining-nums tabular-nums;
                font-family: Inter, sans-serif;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;

                span {
                    color: #495057;
                    font-variant-numeric: lining-nums tabular-nums;
                    font-family: Inter, sans-serif;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem;
                }
            }
        }

        &-verify {
            display: none;
            flex-direction: column;
            grid-gap: 1rem;
            align-self: stretch;

            border-radius: 0.375rem;
            background: #f7f8f9;
        }
    }

    &__not-verify {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 0.75rem;

        color: #6c757d;
        text-align: center;
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Inter, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;

        i {
            font-size: 2.25rem;
            color: #bcc3cd;
        }
    }

    &__overlay-panel {
        .p-overlaypanel-content {
            display: flex;
            width: max-content;
            padding: 0.65625rem 0;
            flex-direction: column;
            align-items: flex-start;
        }

        &-item {
            display: flex;
            padding: 0.65625rem 1.09375rem;
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 0.375rem;
            align-self: stretch;

            color: #495057;
            font-family: "Inter V", sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            cursor: pointer;

            &_red {
                color: #e50076;
            }

            &:hover {
                background: #f7f8f9;
            }
        }
    }

    &__fileupload {
        display: flex;

        .p-fileupload-choose {
            padding: 0;
            background: none !important;
            border: none !important;
            color: #6466e9 !important;
        }

        .p-button-icon-left {
            margin: 0;
        }
    }
}

